import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export class AppTopbar extends Component {

    static defaultProps = {
        onMenuButtonClick: null,
        onTopbarMenuButtonClick: null,
        onTopbarItemClick: null,
        profileMode: null,
        horizontal: false,
        topbarMenuActive: false,
        activeTopbarItem: null,
        onLogout: null,
        profile: null,
        onHandleClickProfile: null
    }

    static propTypes = {
        onMenuButtonClick: PropTypes.func.isRequired,
        onTopbarMenuButtonClick: PropTypes.func.isRequired,
        onTopbarItemClick: PropTypes.func.isRequired,
        profileMode: PropTypes.string.isRequired,
        horizontal: PropTypes.bool.isRequired,
        topbarMenuActive: PropTypes.bool.isRequired,
        activeTopbarItem: PropTypes.string,
        onLogout: PropTypes.func.isRequired,
        profile: PropTypes.any.isRequired,
        onHandleClickProfile: PropTypes.func.isRequired
    }

    constructor() {
        super();
        this.state = { };
    }

    onTopbarItemClick(event, item) {
        if(this.props.onTopbarItemClick) {
            this.props.onTopbarItemClick({
                originalEvent: event,
                item: item
            });
        }
    }

    render() {
        let topbarItemsClassName = classNames('topbar-items fadeInDown', {'topbar-items-visible': this.props.topbarMenuActive});

        return <div className="topbar clearfix">
            <div className="topbar-left">
                <img alt="Logo" src="assets/layout/images/logo_caribmall2.png"
                                     style={{height: 50, width: 100, marginLeft: 25,backgroundImage: "black",marginTop: 6}} className="topbar-logo" />
            </div>

            <div className="topbar-right">
                <button className="p-link" id="menu-button" onClick={this.props.onMenuButtonClick}>
                    <i className="fa fa-angle-left"></i>
                </button>
                <button className="p-link" id="topbar-menu-button" onClick={this.props.onTopbarMenuButtonClick}>
                    <i className="fa fa-bars"></i>
                </button>
                <ul className={topbarItemsClassName}>
                    {(this.props.profileMode === 'top' || this.props.horizontal) &&
                    <li className={classNames('profile-item', {'active-top-menu': this.props.activeTopbarItem === 'profile'})}
                        onClick={(e) => this.onTopbarItemClick(e, 'profile')}>
                        <button className="p-link">
                            <img alt="User" className="profile-image" src="assets/layout/images/avatar01.png" />
                            <span className="topbar-item-name">{this.props.profile.userName}</span>
                            <span className="topbar-item-role">{this.props.profile.area}</span>
                        </button>

                        <ul className="layout-menu fadeInDown">
                            <li role="menuitem">
                                <button className="p-link" onClick={this.props.onHandleClickProfile}>
                                    <i className="fa fa-fw fa-user"></i>
                                    <span>Profile</span>
                                </button>
                            </li>
                            <li role="menuitem">
                                <button className="p-link" onClick={this.props.onLogout}>
                                    <i className="fa fa-fw fa-sign-out"></i>
                                    <span>Logout</span>
                                </button>
                            </li>
                        </ul>
                    </li>}
                </ul>
            </div>
        </div>;
    }
}