import React, { Component } from 'react';
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import Card from "../shared/Card";
import {UsuarioService} from "../../service/login/UsuarioService";
import {Button} from "primereact/components/button/Button";
import {Growl} from "primereact/growl";



class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            middleName: '',
            lastName: '',
            birthday: '',
            preferredCurrency: '',
            phone: '',
            phoneType: '',

            country: '',
            countryBirth: '',
            nationality: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            _state: '',
            postCode: '',
            companyName: '',
            companyEmail: '',
            companyRegistration: '',

            catPhones: [],
            catCountries: [],
            catNationalities: [],
            catIdTypes: [],
            agent_fee: '',
            currencies:[{label: 'Select Currency', value: ''},
                        {label: 'USD', value: 'USD'}]
        };
        this.updateFeeAgent = this.updateFeeAgent.bind(this);
        this.loadProfile = this.loadProfile.bind(this);
        this.changeHandlerFee = this.changeHandlerFee.bind(this);
        this.handleBlur = this.handleBlur.bind(this);

    }

    componentDidMount() {
        const  profile = JSON.parse(localStorage.getItem('profile'));
        if(profile !== undefined && profile != null) {

            const dataProfile = profile.profile;
            let role = profile.profile.rol;
            this.setState({
                role,
                firstName: profile.first_name != null ? profile.first_name : '',
                middleName: dataProfile.middle_name != null ? dataProfile.middle_name : '',
                lastName: profile.last_name != null ? profile.last_name : '',
                birthday: dataProfile.bday != null ? dataProfile.bday : '',
                preferredCurrency: dataProfile.preferred_currency != null ? dataProfile.preferred_currency : '',
                phone: dataProfile.phone_number != null ? dataProfile.phone_number : '',
                phoneType: dataProfile.phone_type != null ? dataProfile.phone_type : '',
                country: dataProfile.country != null ? dataProfile.country : '',
                countryBirth: dataProfile.country_birth != null ? dataProfile.country_birth : '',
                nationality: dataProfile.nationality != null ? dataProfile.nationality : '',
                addressLine1: dataProfile.billing_address_line_1 != null ? dataProfile.billing_address_line_1 : '',
                addressLine2: dataProfile.billing_address_line_2 != null ? dataProfile.billing_address_line_2 : '',
                city: dataProfile.billing_city != null ? dataProfile.billing_city : '',
                _state: dataProfile.billing_state != null ? dataProfile.billing_state : '',
                postCode: dataProfile.billing_post_code != null ? dataProfile.billing_post_code : '',
            });
            this.loadCatalogs();
            this.loadProfile();
        }
    }
    submitHandler = e => {
        e.preventDefault();
        /*const {
            firstName,
            middleName,
            lastName,
            birthday,
            preferredCurrency,
            phone,
            phoneType,
            country,
            countryBirth,
            nationality,
            addressLine1,
            addressLine2,
            city,
            _state,
            postCode,
        } = this.state;*/
        /*const profile = {
            first_name: firstName,
            middle_name: middleName,
            last_name: lastName,
            bday: birthday,
            preferred_currency: preferredCurrency,
            phone_number: phone,
            phone_type: phoneType,
            country: country,
            country_birth: countryBirth,
            nationality: nationality,
            billing_address_line_1: addressLine1,
            billing_address_line_2: addressLine2,
            billing_city: city,
            billing_state: _state,
            billing_post_code: postCode,
        };*/
        /*
        const donationServices = new DonationServices();
        donationServices.updateProfile(profile).then(resp => {
            if (resp.status === 'success') {
                const usuarioService = new UsuarioService();
                usuarioService.getProfile().then(resp2 => {
                    if (resp2.status == 'success') {
                        localStorage.setItem('profile', JSON.stringify(resp2.data));
                    }
                }).catch(error => {
                    toast.error('Error reload profile');
                });
                toast.success('Profile saved');
            }
        }).catch(error => {
            toast.error('Error save profile');
        });
        */
    };

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });

    };


    loadCatalogs(){
        this.usuarioService = new UsuarioService();
        this.usuarioService.getCatalogs().then(resp => {
            if (resp.status === 'success') {
                const catCountries = [];
                resp.catCountries.forEach(element => catCountries.push({label: element[1], value: element[0]}));
                this.setState({
                    catCountries
                });
            }
        }).catch(error => {
            console.log("error: ", error);
            //this.growl.show({severity: 'error', summary: 'Error', detail: "Error load catalogs"});
        });
    }
    updateFeeAgent(){
        this.usuarioService = new UsuarioService();
        const params = {
            country: this.state.agent_country,
            extern_fee: parseFloat(this.state.agent_fee.replace(/[^0-9.]/g, ''))
        }
        this.usuarioService.postUpdateFeeAgent(params).then(resp => {
            if (resp.status === 'success') {
                this.showSucccess("Updated Fee!");
                console.log("ok")
                this.loadProfile()
            }
        }).catch(error => {
            console.log("error: ", error);
            this.showError("Service error!");
        });
    }
    loadProfile() {
        const self = this;
        this.usuarioService = new UsuarioService();
        self.usuarioService.getUsuarioLogueo().then(resp => {
            const agent_country = resp.data.agent_find.country;
            let agent_fee = resp.data.agent_find.extern_fee.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
            this.setState({agent_fee, agent_country});
        }).catch(error => {
        });
        this.loadCatalogs();
    }

    showSucccess(msgDetail) {
        let msg = { severity: 'success', summary: 'Success', detail: msgDetail};
        this.growl.show(msg);
    }

    showError(msgDetail) {
        let msg = { severity: 'error', summary: 'ERROR', detail: msgDetail};
        this.growl.show(msg);
    }

    changeHandlerFee = (e) => {
        let rawValue = e.target.value;
        rawValue = rawValue.replace(/[^0-9.,]+/g, '');
        let numericValue = rawValue.replace(/,/g, '');
        numericValue = parseFloat(numericValue);
        let displayValue = numericValue;
        if (!isNaN(numericValue)) {
            displayValue = numericValue.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        }
        this.setState({ agent_fee_display: displayValue, agent_fee: numericValue });
    }

    handleBlur = () => {
        const num = parseFloat(this.state.agent_fee);
        if (!isNaN(num)) {
            this.setState({ agent_fee: num.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) });
        }
    }

    render() {
        const {
            firstName,
            middleName,
            lastName,
            birthday,
            preferredCurrency,
            phone,
            //phoneType,

            //country,
            //countryBirth,
            //nationality,
            addressLine1,
            addressLine2,
            city,
            _state,
            postCode,

            //catPhones,
            //catCountries,
            //catNationalities,
            //companyName,
            //companyEmail,
            //companyRegistration,
        } = this.state;

        return (
            <Card>
                <Growl ref={(el) => this.growl = el} />
                <div className="p-col-12">
                    <h1>Profile</h1>
                </div>
                <div className="p-grid p-fluid">
                    <div className="card card-w-title">
                        <div className="p-grid">
                            <div className="p-col-12 p-md-4">
                                <h4>First Name</h4>
                                <InputText
                                    placeholder="First Name"
                                    name="firstName"
                                    onChange={this.changeHandler}
                                    value={firstName}
                                />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <h4>Middle Name</h4>
                                <InputText
                                    placeholder="Middle Name"
                                    name="middleName"
                                    onChange={this.changeHandler}
                                    value={middleName}
                                />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <h4>Last Name</h4>
                                <InputText
                                    placeholder="Last Name"
                                    name="lastName"
                                    onChange={this.changeHandler}
                                    value={lastName}
                                />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <h4>Birthday</h4>
                                <InputText
                                    placeholder="yyyy-mm-dd"
                                    name="birthday"
                                    onChange={this.changeHandler}
                                    value={birthday}
                                />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <h4>Preferred Currency</h4>
                                <Dropdown options={this.state.currencies}
                                          value={preferredCurrency}
                                          onChange={this.changeHandler}
                                          autoWidth={false}/>
                            </div>
                            <div className="p-col-12 p-md-4">
                                <h4>Phone</h4>
                                <InputText
                                    placeholder="Phone"
                                    name="phone"
                                    onChange={this.changeHandler}
                                    value={phone}
                                />
                            </div>
                            {/*<div className="p-col-12 p-md-4">
                                <h4>Phone Type</h4>
                                <Dropdown options={catPhones}
                                          value={phoneType}
                                          onChange={this.changeHandler}
                                          autoWidth={false} />
                            </div>*/}
                            {/*<div className="p-col-12 p-md-4">
                                <h4>Country Birth</h4>
                                <Dropdown options={catCountries}
                                          value={countryBirth}
                                          onChange={this.changeHandler}
                                          autoWidth={false} />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <h4>Nationality</h4>
                                <Dropdown options={catNationalities}
                                          value={nationality}
                                          onChange={this.changeHandler}
                                          autoWidth={false} />
                            </div>*/}
                            <div className="p-col-12">
                                <h2>Billing Address</h2>
                            </div>
                            <div className="p-col-12 p-md-4">
                                <h4>Address Line 1</h4>
                                <InputText
                                    placeholder="Address Line 1"
                                    name="addressLine1"
                                    onChange={this.changeHandler}
                                    value={addressLine1}
                                />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <h4>Address Line 2</h4>
                                <InputText
                                    placeholder="Address Line 2"
                                    name="addressLine2"
                                    onChange={this.changeHandler}
                                    value={addressLine2}
                                />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <h4>City</h4>
                                <InputText
                                    placeholder="City"
                                    name="city"
                                    onChange={this.changeHandler}
                                    value={city}
                                />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <h4>Province</h4>
                                <InputText
                                    placeholder="State"
                                    name="_state"
                                    onChange={this.changeHandler}
                                    value={_state}
                                />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <h4>Post Code</h4>
                                <InputText
                                    placeholder="Post Code"
                                    name="postCode"
                                    onChange={this.changeHandler}
                                    value={postCode}
                                />
                            </div>
                            {/*<div className="p-col-12 p-md-4">
                                <h4>Country</h4>
                                <Dropdown options={catCountries}
                                          value={country}
                                          onChange={this.changeHandler}
                                          autoWidth={false} />
                            </div>*/}
                            {/*<div className="p-col-12">
                                <h2>Company</h2>
                            </div>
                            <div className="p-col-12 p-md-4">
                                <h4>Name</h4>
                                <InputText
                                    placeholder="Name"
                                    name="companyName"
                                    onChange={this.changeHandler}
                                    value={companyName}
                                />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <h4>Email</h4>
                                <InputText
                                    placeholder="Email"
                                    name="companyEmail"
                                    onChange={this.changeHandler}
                                    value={companyEmail}
                                />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <h4>Registration</h4>
                                <InputText
                                    placeholder="Registration"
                                    name="companyRegistration"
                                    onChange={this.changeHandler}
                                    value={companyRegistration}
                                />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <Button label="Submit" style={{marginBottom:'10px'}} onClick={this.submitHandler}/>
                            </div>*/}
                            {
                                this.state.role === "agent" && (
                                    <React.Fragment>
                                        <div className="p-col-12">
                                            <h1 style={{marginTop: '30px'}}>Fee Configuration</h1>
                                        </div>
                                        <div className="p-col-12 p-md-4">
                                            <h4>Country</h4>
                                            <Dropdown options={this.state.catCountries}
                                                      name="agent_country"
                                                      value={this.state.agent_country}
                                                      onChange={this.changeHandler}
                                                      autoWidth={false}/>
                                        </div>
                                        <div className="p-col-12 p-md-4">
                                            <h4>Fee</h4>
                                            <InputText
                                                placeholder="Fee"
                                                name="agent_fee"
                                                onChange={this.changeHandlerFee}
                                                onBlur={this.handleBlur}
                                                value={this.state.agent_fee}
                                            />
                                        </div>
                                        <div className="p-col-12 p-md-4">
                                            <h4>Update Fee</h4>
                                            <div>
                                                <Button label="Update Fee" onClick={this.updateFeeAgent}/>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                            }
                        </div>
                    </div>
                </div>
            </Card>
        )
    }
}

export default Profile;