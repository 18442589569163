import axios from 'axios';

export class UsuarioService {
    getConfig = (params) => {
        let access_token = sessionStorage.getItem('access_token');
        return {
            params: params,
            headers: {
                'Authorization': `Bearer ${access_token}`,
            }
        };
    }
    getConfig2 = params => {
        return {
            params,
            headers: {
                'Content-Type': 'application/json',
            },
        };
    };


    postConfig = (contentType) => {
        let access_token = sessionStorage.getItem('access_token');
        if (!contentType) {
            contentType = 'application/json';
        }
        return {
            headers: {
                'Authorization': `Bearer ${access_token}`,
                'Content-Type': contentType
            }
        };
    }

    getUsuarioLogueo() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_OAUTH3}/api/admin/profile/`, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    postReportHistoryPayment(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_USERS}/api/v/2.0/service/broker/payment/history`, params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }
    linkKycPdf(params, item) {
        return fetch(item, {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(resp => resp.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank');
            })
            .catch(error => {
                console.error(error);
            });
    }


    getLstMerchants() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_USERS}/api/admin/merchants/`, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    postReportFee(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_USERS}/api/admin/report/payment/fee/`, params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    postReportFeeDetail(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_USERS}/api/admin/report/payment/fee/detail/`, params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    postReportBtcReceived(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_USERS}/api/admin/btc/payment/btc/received/history/`, params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    postReportBtcPayment(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_USERS}/api/admin/btc/payment/btc/payment/history/`, params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    getReportBtcMonthly() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_USERS}/api/admin/btc/payment/btc/payment/montly/sumary/`, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    getReportBtcLastTransactions() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_USERS}/api/admin/btc/payment/btc/received/history/last/`, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    getListExternalBtc() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_USERS}/api/admin/config/external/list/`, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    getListAuthorizeExternalBtc() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_USERS}/api/admin/config/external/list/authorize/`, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    postAddExternalBtc(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_USERS}/api/admin/config/external/add/`, params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    postVerificationCodeExternalBtc(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_USERS}/api/admin/sns/btc/external/validateCode/`, params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    postDeleteExternalBtc(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_USERS}/api/admin/config/external/delete/`, params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    postCreateInvoiceBtc(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_USERS}/api/admin/service/btc/external/payment/create/`, params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    postPaymentExternalBtc(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_USERS}/api/admin/service/btc/external/payment/`, params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    postReportGeneralDonations(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_USERS}/api/admin/donation/reportes/general/`, params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    getReportGeneralPaymentDonations() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_USERS}/api/admin/donation/reportes/general/payment/`, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    getReportTotalUsersDonations() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_USERS}/api/admin/donation/info/users/`, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    getReportSumaryDonations() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_USERS}/api/admin/donation/payment/montly/sumary/`, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    getReportCountryDonations() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_USERS}/api/admin/donation/reportes/general/country/`, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    getLastDonations() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_USERS}/api/v/2.0/service/donation/list/last/`, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    postReportDonationsTrans(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_USERS}/api/v/2.0/service/donation/reportes/transactions/`, params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    postReportDonationsCausas(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/v/2.0/service/donation/reportes/causas/`, params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    getReportWebHookTrans() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_OAUTH3}/api/admin/data/transactions/`, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    getReportWebHookTransProd() {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_OAUTH3}/api/admin/pending/transactions/`, {}, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    postSearchTransactionCode(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_OAUTH3}/api/admin/pending/search/`, params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    getListTranactionsAgent() {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_OAUTH3}/api/admin/pending/agent/transactions/`, {}, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    postForgotPassword(params) {
        return axios.post(
            `${process.env.REACT_APP_ENDPOINT_OAUTH3}/api/forgotPassword/`,
            params,
            this.getConfig2()).then(resp => resp.data);
    }

    resetPasswordService(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_OAUTH3}/api/resetPassword/admin/`,
            params).then(resp => {
            return resp.data;
        });
    }


    /*getReportWebHookTransByToken(card_token) {
            return axios.get(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/admin/data/transactions/${card_token}/`, this.getConfig()).then(
                resp => {
                    return resp.data;
                });
        */
    getReportWebHookTransByToken(card_token) {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_OAUTH3}/api/admin/data/transactions/${card_token}/`, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    getReportWebHookTransPage(link) {
        return axios.get(link, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    getReportWebHookTransSumary() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/admin/dynamodb/sumary/`, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    getReportWebRdsTransSumary() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/admin/data/sumary/`, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    getReporProfiles(params) {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/admin/data/profile/`, params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    /*getAccountList(email) {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/admin/data/account/${email}/list/`, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }*/
    getAccountList(email) {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_OAUTH3}/api/admin/data/account/${email}/list/`, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    getTransactionList(email, card_token) {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/admin/data/account/${email}/${card_token}/trans/`, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    getReportSumaryAccount(email, card_token) {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_OAUTH3}/api/admin/data/account/${email}/${card_token}/sumary/`, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    /*postTransCardTokenBalanceAirtm(params) {
            return axios.post(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/admin/tokens/transaction/airtm/list/`, params, this.getConfig()).then(
                resp => {
                    return resp.data;
                });
        }*/
    postTransCardTokenBalanceAirtm(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_OAUTH3}/api/admin/data/individual/list/`, params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    postTransCardTokenBalance(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/admin/tokens/transaction/admin/list/`, params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    /*postReportIssuingSumary(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/admin/issuing/sumary/`, params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }*/
    postReportIssuingSumary(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_OAUTH3}/api/admin/issuing/sumary/`, params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    postExportReportIssuingSumary(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/admin/issuing/sumary/export/`, params, {responseType: 'blob'}).then(
            resp => {
                return resp.data;
            });

    }

    postReportAirtmIssuingSumary(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/admin/data/airtm/sumary/`, params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    getReportAirtmCardActivationIssuingSerie() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/admin/data/airtm/card_activation/`, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    postReportIssuingHistory(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/admin/issuing/sumary/history/`, params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    postReportIssuingFundFeeWallet(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/admin/issuing/sumary/history/FundFeeWallet/`, params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    postReportIssuingMaps(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/admin/issuing/sumary/maps/`, params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    /*postBalanceCardTokenTpp(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/admin/tpp/card/balance/`, params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }*/
    postBalanceCardTokenTpp(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_OAUTH3}/api/admin/card/balance/`, params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    /*getAllBestProfiles(params) {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/admin/data/all/profile/`, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }*/
    getAllBestProfiles(params) {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_OAUTH3}/api/admin/data/all/profile/`, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    getAllAccountsByProfile(email) {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/admin/data/account/${email}/`, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    getInfoProfileBest(email) {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_OAUTH3}/api/admin/tokens/account/${email}/info/`, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    getAlertRecipients() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_OAUTH3}/api/admin/alert/recipient/list/`, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    postCreateAlertRecipients(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/admin/alert/recipient/create/`, params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    postUpdateAlertRecipients(params, id) {
        params['id'] = id
        return axios.put(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/admin/alert/recipient/${id}/update/`, params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    postDeleteAlertRecipients(params, id) {
        return axios.delete(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/admin/alert/recipient/${id}/delete/`, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    getMasterAlert() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_OAUTH3}/api/admin/alert/list/`, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    putUpdateMasterAlert(params, id) {
        return axios.put(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/admin/alert/${id}/update/`, params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    sendTestSMSMessage(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/admin/alert/recipient/test/send/`, params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    getFunWalletLog() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/admin/issuing/fundwallet/logs/`, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    getFunWalletLoadTransferRLog() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/admin/issuing/fundwallet/loadtreversals/`, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    getAllDeposits() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/admin/deposit/list/`, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    putUpdateDeposit(params, id) {
        return axios.put(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/admin/deposit/${id}/update/`, params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    postCreateDeposit(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/admin/deposit/create/`, params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    postDeleteDeposit(params, id) {
        return axios.delete(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/admin/deposit/${id}/delete/`, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    postTokenStatusCard(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_USERS}/api/admin/tokens/status/card/`, params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    getUnloadCardList(){
        return axios.get(`${process.env.REACT_APP_ENDPOINT_USERS}/api/admin/card/unload/list/`, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    postTokenStatusCardDetail(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_USERS}/api/admin/tokens/detail/status/card/`, params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    postCreateunloadcard(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_USERS}/api/admin/card/unload/create/`, params, this.getConfig()).then(
            resp => {
                return resp;
            });
    }

    postDeleteUnloadcard(id) {
        return axios.delete(`${process.env.REACT_APP_ENDPOINT_USERS}/api/admin/card/unload/${id}/delete/`, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }
    postPendingAuthorize(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_OAUTH3}/api/admin/pending/authorize/`, params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    postPendingDecline(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_OAUTH3}/api/admin/pending/cancel/`, params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }
    postReportWalletTransactions() {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_OAUTH3}/api/admin/wallet/transactions/`, {}, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    postListKyc(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_OAUTH3}/api/admin/report/kyc/sumary/`, params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    postListDetailsKyc(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_OAUTH3}/api/admin/report/kyc/detail/`, params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    getCatalogs() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_OAUTH3}/api/profile/list/catalogos/`, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    postUpdateFeeAgent(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_OAUTH3}/api/admin/profile/agent/update/`, params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    postListAgentsFee() {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_OAUTH3}/api/admin/list/agents/fee/`, {}, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    postReportSeries(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_OAUTH3}/api/admin/issuing/series/`, params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }
}
