import React, { Component } from 'react';
import Card from '../shared/Card';
import {HeisenbergService} from "../../service/heisenberg/HeisenbergService";
import {CarService} from "../../service/CarService";
import {UsuarioService} from "../../service/login/UsuarioService";
import {Growl} from "primereact/growl";
import {ProgressSpinner} from "primereact/progressspinner";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {Dialog} from "primereact/components/dialog/Dialog";
import {Button} from "primereact/components/button/Button";
import {InputText} from "primereact/components/inputtext/InputText";


class DashboardAlert extends Component {

    constructor() {
        super();
        this.apiHeisenberg = new HeisenbergService()
        this.usuarioService = new UsuarioService()
        this.state = {
            globalFilter: null,
            dialogVisible: false,
            expandedRows: {},
            selectedRowData: null,
            data: new FormData(),
            dataTableSelectValue: new FormData(),
            dataTableSelectValueAlert: new FormData(),
            fileName: "",
            merchantId: "",
            listRecipient: [],
            listAlerts: [],
            dataTableValue: [],
            monthlyDataset: {},
            merchantData: {},
            mapCountryData: {},
            arratCountryData: [],
            lastDonations: [],
            chargedYet: true,
            newDialog: false,
            newAlertDialog: false,
            deleteDialog: false,
            isEditCauses: false,
            isEditAlert: false,
            reportTypeData: {},
            alert_data: {},
            test_phone: '',
            test_name: '',
            master_min:'',
            master_txt:'',
            master_name:'',
        }
        this.carService = new CarService();

        this.getMaster = this.getMaster.bind(this);
        this.linkKYCimage = this.linkKYCimage.bind(this);
        this.linkKYCpdf = this.linkKYCpdf.bind(this);
    }

    componentDidMount() {
        this.getMaster();
    }




    showSucccess(msgDetail) {
        let msg = { severity: 'success', summary: 'OK', detail: msgDetail, life: 7000};
        this.growl.show(msg);
    }




    getMaster(){
        this.setState({chargedYet: false});
        this.usuarioService = new UsuarioService()
        this.usuarioService.postListKyc().then(resp => {
            const lst_kycs = resp.data
            this.setState({lst_kycs,
                chargedYet: true});
        }).catch(error => {
        });
    }







    hideDialog = () => {
        this.setState({
            newDialog: false,
            newAlertDialog: false,
            deleteDialog: false
        });
    }

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
    renderDetailsButton = (rowData) => {
        return (
            <Button label="Details" className="p-button-raised p-button-info" onClick={() => this.handleDetailsClick(rowData)}/>
        );
    }
    handleDetailsClick = (rowData) => {
        this.setState({chargedYet: false})
        const params = {
            kyc_id: 1,
            reference: rowData.reference
        }
        this.usuarioService.postListDetailsKyc(params).then(resp => {
            const items = resp.data.proofs;
            const document = items.document;
            const face = items.face;
            const verification_report = items.verification_report;
            const access_token = items.access_token;
            this.setState({items, document, face, verification_report, access_token,
                chargedYet: true});
        }).catch(error => {
        });
        this.setState({
            selectedRowData: rowData,
            dialogVisible: true,
        });
    }

    linkKYCimage = (item) => {
        this.setState({chargedYet: false});
        const params = {
            access_token: this.state.access_token
        }
        return fetch(item.proof, {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(resp => {
                    return resp.blob().then(blob => {
                        const url = window.URL.createObjectURL(blob);
                        this.setState({imageUrl: url, dialogImage: true,chargedYet: true});
                    });
            })
            .catch(error => {
                console.error(error);
            });
    }

    linkKYCpdf = (item) => {
        this.setState({chargedYet: false});
        const params = {
            access_token: this.state.access_token
        }
        this.usuarioService.linkKycPdf(params, item).then(resp => {
            this.setState({chargedYet: true});
        }).catch(error => {
        });
    }
    rowExpansionTemplate = (data) => {
        const dateTemplate = (rowData) => {
            let [date] = rowData.d_created.split("T");
            return <span>{date}</span>;
        };
        return (
            <DataTable value={data.data}
                       reorderableColumns={true}
                       selectionMode="single"
                       paginator={true}
                       rows={30}
                       resizableColumns={true}
                       selection={this.state.dataTableSelectValue}
                       onSelectionChange={(e) => this.setState({dataTableSelectValue: e.value})}
                       style={{boxShadow: '0 5px 9px #00283A'}}>
                <Column body={this.renderDetailsButton} style={{width: '6em'}}></Column>
                <Column field="user__id" header="User ID" sortable={true} className="ta-center" style={{width: '3em'}}></Column>
                <Column field="user__username" header="Username" sortable={true} className="ta-center"></Column>
                <Column field="declined_reason" header="Declined Reason" sortable={true} className="ta-center"></Column>
                <Column field="d_created"body={dateTemplate} header="Date Created" sortable={true} className="ta-center"></Column>
            </DataTable>
        );
    }


    render() {
        let header = (
            <div style={{'textAlign':'right'}}>
                <i className="pi pi-search" style={{margin:'4px 4px 0 0'}}></i>
                <InputText type="search" onInput={(e) => this.setState({globalFilter: e.target.value})} placeholder="Global Search" size="50"/>
            </div>
        );
        const dateTemplate = (rowData) => {
            let [date] = rowData.d_created.split("T");
            return <span>{date}</span>;
        };
        const statusKyc = (rowData) => {
            let status = rowData.declined_reason;
            let color;
            let icon;

            switch (status) {
                case 'verification.accepted':
                    color = 'rgba(123, 197, 100, 0.8)'; // Verde más fuerte
                    status = 'Verification Accepted';
                    icon = <i className="fa fa-check-circle-o" aria-hidden="true"></i>;
                    break;
                case 'request.pending':
                    color = 'rgba(255, 255, 0, 0.8)'; // Amarillo más fuerte
                    status = 'Request Pending';
                    icon = <i className="fa fa-clock-o" aria-hidden="true"></i>;
                    break;
                case 'request.timeout':
                    color = 'rgba(255, 0, 0, 0.8)'; // Rojo más fuerte
                    status = 'Request Timeout';
                    icon = <i className="fa fa-times-circle-o" aria-hidden="true"></i>;
                    break;
                case 'request.invalid':
                    color = 'rgba(255, 0, 0, 0.8)'; // Rojo más fuerte
                    status = 'Request Invalid';
                    icon = <i className="fa fa-times-circle-o" aria-hidden="true"></i>;
                    break;
                case 'verification.declined':
                    color = 'rgba(255, 0, 0, 0.8)'; // Rojo más fuerte
                    status = 'Verification Declined';
                    icon = <i className="fa fa-times-circle-o" aria-hidden="true"></i>;
                    break;
                case 'verification.cancelled':
                    color = 'rgba(255, 0, 0, 0.8)'; // Rojo más fuerte
                    status = 'Verification Cancelled';
                    icon = <i className="fa fa-times-circle-o" aria-hidden="true"></i>;
                    break;
                default:
                    break;
            }

            return (
                <div
                    style={{
                        backgroundColor: color,
                        width: '40%',
                        margin: 'auto',
                        textAlign: 'center',
                        padding: '5px',
                        color: 'black',
                        borderRadius: '8px',
                    }}
                >
                    {icon} {status}
                </div>
            );
        };
        return (
            <div className="pageWrapper">
                <Growl ref={(el) => this.growl = el}/>
                <div className="p-grid">
                    <div className="p-grid">
                        <div className="p-col-9 ta-right" style={{position: "absolute", marginTop: -60}}>
                            {this.state.chargedYet ? '' :
                                <ProgressSpinner style={{width: '50px', height: '50px'}}
                                                 animationDuration=".5s"/>}
                        </div>
                    </div>
                    <Card height="height-100">
                        <div className="p-col-12">
                            <Card>
                                <DataTable value={this.state.lst_kycs}
                                           expandedRows={this.state.expandedRows}
                                           onRowToggle={(e) => this.setState({ expandedRows: e.data })}
                                           responsiveLayout="scroll"
                                           globalFilter={this.state.globalFilter}
                                           header={header}
                                           rowExpansionTemplate={this.rowExpansionTemplate}
                                           dataKey="username"
                                           style={{ width: '100%' }} >
                                    <Column expander style={{ width: '70px' }} />
                                    <Column field="username" header="Name" sortable style={{ textAlign: 'left' }}
                                            body={(rowData) => (
                                                <>
                                                    <i className="pi pi-user" style={{ marginRight: '10px', marginLeft: '10px' }}></i>
                                                    <span>{rowData.username}</span>
                                                </>
                                            )}/>
                                    <Column field="declined_reason" body={statusKyc}header="Status KYC" sortable style={{ textAlign: 'center' }}/>
                                    <Column field="d_created" body={dateTemplate} header="Created" sortable style={{ textAlign: 'center' }}/>
                                </DataTable>
                            </Card>
                        </div>
                    </Card>
                    <Dialog
                        header="Details"
                        style={{width: '80vw', maxHeight: '80vh', maxWidth: '500px', maxheight: '600px'}}
                        visible={this.state.dialogVisible}
                        modal={true}
                        onHide={() => this.setState({dialogVisible: false})}
                    >
                        {this.state.selectedRowData &&
                            <table style={{paddingBottom: '30px'}}>
                                <tbody>
                                <tr>
                                    <th style={{textAlign: 'left', color: '#007bff'}}>User:</th>
                                    <td>{this.state.selectedRowData.user}</td>
                                </tr>
                                <tr>
                                    <th style={{textAlign: 'left', color: '#007bff'}}>Status KYC:</th>
                                    <td>{this.state.selectedRowData.declined_reason}</td>
                                </tr>
                                <tr>
                                    <th style={{textAlign: 'left', color: '#007bff'}}>Created:</th>
                                    <td>{this.state.selectedRowData.d_created.split("T")[0]}</td>
                                </tr>
                                </tbody>
                            </table>
                        }
                        <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: '80vw'}}>
                            {this.state.document &&
                                <Button icon="pi pi-external-link" label="Document"
                                        style={{marginRight: '1vw', width: '20vw'}}
                                        onClick={() => this.linkKYCimage(this.state.document)}
                                        disabled={!this.state.chargedYet}
                                />}
                            {this.state.face &&
                                <Button icon="pi pi-external-link" label="Face"
                                        style={{marginRight: '1vw', width: '20vw'}}
                                        onClick={() => this.linkKYCimage(this.state.face)}
                                        disabled={!this.state.chargedYet}
                                />}
                            {this.state.verification_report &&
                                <Button icon="pi pi-external-link" label="Verification Report"
                                        style={{width: '20vw'}}
                                        onClick={() => this.linkKYCpdf(this.state.verification_report)}
                                        disabled={!this.state.chargedYet}
                                />}
                        </div>
                    </Dialog>
                    <Dialog header="Document" visible={this.state.dialogImage} modal={true}
                            onHide={() => this.setState({dialogImage: false})}
                            style={{width: '25vw', height: '60vh', padding: 0, margin: 0}}>
                        <img src={this.state.imageUrl} alt="Imagen" style={{
                            display: 'block',
                            margin: 'auto',
                            maxWidth: '100%',
                            maxHeight: '100%',
                            objectFit: 'contain'
                        }}/>
                    </Dialog>
                </div>
            </div>
        );
    }
}

export default DashboardAlert;
