import React, {Component} from 'react';
import {Route, withRouter} from 'react-router-dom';
import App from "./App";
import Login from "./components/login";
import Error from "./pages/Error";
import NotFound from "./pages/NotFound";
import Access from "./pages/Access";
import {Wizard} from "./pages/Wizard";
import Forgot_Password from "./pages/Forgot_Password";
import ConfirmForgotPassword from "./pages/ConfirmForgotPassword";


class AppWrapper extends Component {
	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			window.scrollTo(0, 0)
		}
	}

	render() {
		if (this.props.location.pathname.includes("/forgot/")) {
			return <Route path="/forgot/:idUser/:token/" component={ConfirmForgotPassword}/>
		} else {
			switch (this.props.location.pathname) {
				case "/login":
					return <Route path="/login" component={Login}/>
				case "/forgot_password":
					return <Route path="/forgot_password" component={Forgot_Password}/>
				case "/error":
					return <Route path="/error" component={Error}/>
				case "/notfound":
					return <Route path="/notfound" component={NotFound}/>
				case "/access":
					return <Route path="/access" component={Access}/>
				case "/wizard":
					return <Route path="/wizard" component={Wizard}/>
				default:
					return <App/>;
			}
		}
	}
}

export default withRouter(AppWrapper);
