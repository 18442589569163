import React, { Component } from 'react';
import Card from '../shared/Card';
import {Button} from "primereact/components/button/Button";
import {HeisenbergService} from "../../service/heisenberg/HeisenbergService";
//import TextSemiBold from "../shared/Typography/TextSemiBold";
//import {Chart} from "primereact/components/chart/Chart";
//import {formatCurrency, formatNumber} from "../utils/commonFunctions";
//import {Calendar} from "primereact/calendar";
import {CarService} from "../../service/CarService";
import {UsuarioService} from "../../service/login/UsuarioService";
import {Growl} from "primereact/growl";
import {ProgressSpinner} from "primereact/progressspinner";
//import {SelectButton} from "primereact/selectbutton";
//import classNames from "classnames";
import {TabPanel, TabView} from "primereact/tabview";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
//import {MapplicWrapper} from "../Wrapper/MapplicWrapper";
import {Toolbar} from "primereact/toolbar";
import {Dialog} from "primereact/components/dialog/Dialog";
import {InputText} from "primereact/components/inputtext/InputText";
import {InputTextarea} from "primereact/components/inputtextarea/InputTextarea";
import PhoneInput from "react-phone-input-2";

class DashboardAlert extends Component {

    constructor() {
        super();
        this.apiHeisenberg = new HeisenbergService()
        this.usuarioService = new UsuarioService()
        this.state = {
            data: new FormData(),
            dataTableSelectValue: new FormData(),
            dataTableSelectValueAlert: new FormData(),
            fileName: "",
            merchantId: "",
            listRecipient: [],
            listAlerts: [],
            dataTableValue: [],
            monthlyDataset: {},
            merchantData: {},
            mapCountryData: {},
            arratCountryData: [],
            lastDonations: [],
            chargedYet: true,
            newDialog: false,
            newAlertDialog: false,
            deleteDialog: false,
            isEditCauses: false,
            isEditAlert: false,
            reportTypeData: {},
            alert_data: {},
            test_phone: '',
            test_name: '',
            master_min:'',
            master_txt:'',
            master_name:'',

        }
        this.carService = new CarService();

        this.callListPrincipal = this.callListPrincipal.bind(this);
        this.getMaster = this.getMaster.bind(this);
        this.updateMaster = this.updateMaster.bind(this);
        this.getBalanceNode = this.getBalanceNode.bind(this);
        this.getReportGralPay = this.getReportGralPay.bind(this);
        this.sendTestMessage = this.sendTestMessage.bind(this);
    }

    componentDidMount() {
        this.callListPrincipal();
        this.getBalanceNode();
        this.getMaster();
        this.callReset();
    }

    getBalanceNode(){



    }

    getReportGralPay(){



    }


    showSucccess(msgDetail) {
        let msg = { severity: 'success', summary: 'OK', detail: msgDetail, life: 7000};
        this.growl.show(msg);
    }

    callListPrincipal(){
        console.log('callListPrincipal')
        this.usuarioService = new UsuarioService()

        /*this.usuarioService.getAlertRecipients().then(resp => {
            console.log(JSON.stringify(resp))
            const listRecipient = resp;
            this.setState({
                listRecipient,
                chargedYet: true});


        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });*/


    }

    sendTestMessage(){
        this.usuarioService = new UsuarioService()
        const params={
            phone:this.state.test_phone,
            txt:this.state.master_txt
            }
        this.setState({
            chargedYet: false});
        this.usuarioService.sendTestSMSMessage(params).then(resp => {
            if(resp.status === 'success'){
                this.showSucccess(resp.time_react)
            }
            this.setState({
                chargedYet: true});
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });
        this.setState({
            chargedYet: true});
    }

    getMaster(){
        this.usuarioService = new UsuarioService()
        /*this.usuarioService.getMasterAlert().then(resp => {
            const listAlerts = [resp[4]];
            const alert_data = listAlerts[0];
            const master_txt=alert_data.message;
            const master_min=alert_data.min;
            this.setState({
                listAlerts,alert_data,master_txt,master_min,
                chargedYet: true});
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });*/
    }


    updateMaster(){
        this.usuarioService = new UsuarioService()
        const params={
                    id : this.state.alert_data.id,
                    min : this.state.master_min,
                    message : this.state.master_txt,
        }
        this.usuarioService.putUpdateMasterAlert(params,this.state.alert_data.id).then(resp => {
            const list = resp;
            const alert_data = list[0];
            const master_txt=alert_data.message;
            const master_min=alert_data.min;
            this.setState({
                alert_data,master_txt,master_min,
                chargedYet: true});
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });


    }

    callSearch = (periodo) => {
        console.log('callSearch');
        let donation_period=periodo;
        this.setState({donation_period})
        this.getReportSumary(donation_period)
        this.getReportSumaryAirtm(donation_period)
        this.getReportMaps(donation_period)
    }

    callReset = () => {

    }

    dialogAction = action => () => {
        const {
            name,
            phone,
            dataTableSelectValue,
            isEditCauses,
        } = this.state;
        this.usuarioService = new UsuarioService();
        switch (action) {
            case 'newCauses':
                if(name !== '' && phone !== ''){
                    const params = {
                        'name': name,
                        'phone': phone
                    };
                    if (isEditCauses) {
                        this.usuarioService.postUpdateAlertRecipients(params, dataTableSelectValue.id).then(resp => {
                            this.callListPrincipal();
                            this.hideDialog();
                        }).catch(error => {
                            this.showError(error.message);
                            this.setState({chargedYet: true});
                        });
                    } else {
                        this.usuarioService.postCreateAlertRecipients(params).then(resp => {
                            this.callListPrincipal();
                            this.hideDialog();
                        }).catch(error => {
                            this.showError(error.message);
                            this.setState({chargedYet: true});
                        });
                    }
                } else {
                    this.showError('Merchant, Label and Amount are mandatory');
                }
                break;
            case 'deleteCauses':
                const params = {status: '0'};
                this.usuarioService.postDeleteAlertRecipients(params, dataTableSelectValue.id).then(resp => {
                    this.callListPrincipal();
                    this.hideDialog();
                }).catch(error => {
                    this.showError(error.message);
                    this.setState({chargedYet: true});
                });
                break;
            default:
                break;
        }
    }


    dialogAlertAction = action => () => {
        const {
            master_min,
            master_txt,
            dataTableSelectValue,
            //isEditAlert,
        } = this.state;
        this.usuarioService = new UsuarioService();
        if (master_min !== '' && master_txt !== '') {
            const params = {
                'min': master_min,
                'message': master_txt
            };
        this.usuarioService.putUpdateMasterAlert(params, dataTableSelectValue.id).then(resp => {
            this.getMaster();
            this.hideDialog();
        }).catch(error => {
            this.showError(error.message);
            this.setState({chargedYet: true});
        });

        }
    }

    showDialog = dialog => () => {
        const {
            dataTableSelectValue
        } = this.state;
        this.hideDialog();
        this.setState({
            name:'',
            phone: '',
            isEditCauses:false,
        });
        if(dialog === 'newDialog'){
            this.setState({[dialog]: true});
        } else {
            if (dataTableSelectValue != null) {
                const showDialog = dialog=== 'editDialog' ? 'newDialog' : dialog;
                if(dialog === 'editDialog'){
                    this.setState({isEditCauses: true});
                }
                this.setState({[showDialog]: true,
                    name: dataTableSelectValue.name,
                    phone: dataTableSelectValue.phone});
            } else {
                const msg = { severity: 'error', summary: 'You need', detail: 'You need select a row'};
                this.growl.show(msg);
            }
        }
    }

    showAlertDialog = dialog => () => {

        const {
            dataTableSelectValue
        } = this.state;
        const test_phone='';
        if (dataTableSelectValue != null) {
            this.setState({isEditAlert: true});
            const master_min=dataTableSelectValue.min;
            const master_txt=dataTableSelectValue.message;
            const master_name=dataTableSelectValue.name;
            this.setState({newAlertDialog:true,master_min,master_txt,master_name,test_phone});
        } else {
            const msg = { severity: 'error', summary: 'You need', detail: 'You need select a row'};
            this.growl.show(msg);
        }

    }

    hideDialog = () => {
        this.setState({
            newDialog: false,
            newAlertDialog: false,
            deleteDialog: false
        });
    }

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    /**
     * Metodo para mostrar mensaje de error cuando el usuario y/o password son incorrectos
     * params:
     * return: se habilita mensaje de error
     **/
    //showSucccess(msgDetail) {
     //   let msg = { severity: 'success', summary: 'OK', detail: msgDetail};
     //   this.growl.show(msg);
    //}

    showError(msgDetail) {
        let msg = { severity: 'error', summary: 'ERROR', detail: msgDetail};
        this.growl.show(msg);
    }

    /**
     * Metodo para mostrar mensaje de alerta cuando el usuario y/o password estan vacios
     * params:
     * return: se habilita mensaje de alerta
     **/
    showWarn() {
        let msg = { severity: 'warn', summary: 'AVISO', detail: 'You must specify username and password' };
        this.growl.show(msg);
    }

    /**
     * Metodo para mostrar mensaje de alerta generico
     * params:
     * return: se habilita mensaje de alerta
     **/
    showMsg(severityMsg, summaryMsg, detailMsg){
        let msg = { severity: severityMsg, summary: summaryMsg, detail: detailMsg };
        this.growl.show(msg);
    }

    render() {

        //const handleClick = (e, countryCode) => {
        //    console.log(countryCode);
        //};

        const footerDialog = action => (
            <React.Fragment>
                <Button label="Cancel" icon={action === 'deleteCauses' ? 'pi pi-check' : 'pi pi-times'}
                        className="p-button-text" onClick={this.hideDialog} />
                <Button label={action === 'newCauses' ? (!this.state.isEditCauses ?  'New' : 'Update') : 'Delete'}
                        icon={action === 'deleteCauses' ? 'pi pi-times' : 'pi pi-check'}
                        className={action === 'deleteCauses' ? 'p-button-danger' : ''}
                        onClick={this.dialogAction(action)} />
            </React.Fragment>
        );

        const dialogs = (
            <div>
                <Dialog visible={this.state.newDialog} style={{width: '450px'}}
                        header="Recipent" modal className="p-fluid" footer={footerDialog('newCauses')}
                        onHide={this.hideDialog}>
                    <div className="p-field" >
                        <div className="p-field p-grid mt-5">
                            <label htmlFor="dialogNewLabel" className="p-d-block textHorizontal-width-80 mt-14">Name:</label>
                            <div className="p-col">
                                <InputText
                                    placeholder="Name"
                                    name="name"
                                    onChange={this.changeHandler}
                                    value={this.state.name}
                                    id="name"
                                />
                            </div>
                        </div>
                        <div className="p-field p-grid mt-5">
                            <label htmlFor="dialogNewDescription" className="p-d-block textHorizontal-width-80 mt-14">Phone:</label>
                            <div className="p-col">
                                <PhoneInput
                                    placeholder="Phone"
                                    name="phone"
                                    value={this.state.phone}
                                    enableSearch={true}
                                    onChange={phone => this.setState({ phone: phone })}
                                />
                            </div>
                        </div>
                    </div>
                </Dialog>
                <Dialog visible={this.state.deleteDialog} style={{width: '450px', height: '200px'}}
                        header="Delete Cause" modal className="p-fluid" footer={footerDialog('deleteCauses')}
                        onHide={this.hideDialog}>
                    <div className="p-field">
                        {this.state.dataTableSelectValue != null ? <p> <b> Name: </b> {this.state.dataTableSelectValue.name} </p> : ''}
                        {this.state.dataTableSelectValue != null ? <p> <b> Phone: </b> {this.state.dataTableSelectValue.phone} </p> : ''}
                    </div>
                </Dialog>
            </div>
        );

        const footerAlertDialog = action => (
            <React.Fragment>
                <Button label="Cancel" icon={'pi pi-times'}
                        className="p-button-text" onClick={this.hideDialog} />
                <Button label={'Update'}
                        icon={action === 'deleteCauses' ? 'pi pi-times' : 'pi pi-check'}
                        className={action === 'deleteCauses' ? 'p-button-danger' : ''}
                        onClick={this.dialogAlertAction(action)} />
                <Button label='Send SMS' onClick={this.sendTestMessage} />
            </React.Fragment>
        );

        const dialogs_alert = (
            <div>
                <Dialog visible={this.state.newAlertDialog} style={{width: '450px'}}
                        header="Alert" modal className="p-fluid" footer={footerAlertDialog('Update')}
                        onHide={this.hideDialog}>
                    <div className="p-field">
                        <div className="p-field p-grid">
                            <label htmlFor="dialogNewLabel" className="p-d-block textHorizontal-width-80">Name:  </label>
                            <span>{this.state.master_name} </span>
                        </div>
                        <div className="p-field p-grid mt-12">
                            <label htmlFor="dialogNewDescription" className="p-d-block textHorizontal-width-80 mt-14">Test Phone:</label>
                            <div className="p-col">
                                <PhoneInput
                                    value={this.state.test_phone}
                                    name="Phone"
                                    enableSearch={true}
                                    onChange={phone => this.setState({test_phone: phone})}
                                />
                            </div>
                        </div>
                        <div className="p-field p-grid mt-5">
                            <label htmlFor="dialogNewDescription" className="p-d-block textHorizontal-width-80 mt-14">Min:</label>
                            <div className="p-col">
                                <InputText
                                    placeholder="Min"
                                    name="master_min"
                                    onChange={this.changeHandler}
                                    value={this.state.master_min}
                                    id="master_min"
                                />
                            </div>
                        </div>
                        <div className="p-field p-grid mt-5">
                            <label htmlFor="dialogNewDescription" className="p-d-block textHorizontal-width-80 mt-14">Txt:</label>
                            <div className="p-col">
                                <InputTextarea
                                    placeholder="Txt"
                                    name="master_txt"
                                    onChange={this.changeHandler}
                                    value={this.state.master_txt}
                                    id="master_txt"
                                />
                            </div>
                        </div>
                    </div>
                </Dialog>
                <Dialog visible={this.state.deleteDialog} style={{width: '450px', height: '200px'}}
                        header="Delete Cause" modal className="p-fluid" footer={footerDialog('deleteCauses')}
                        onHide={this.hideDialog}>
                    <div className="p-field">
                        {this.state.dataTableSelectValue != null ? <p> <b> Name: </b> {this.state.dataTableSelectValue.name} </p> : ''}
                        {this.state.dataTableSelectValue != null ? <p> <b> Phone: </b> {this.state.dataTableSelectValue.phone} </p> : ''}
                    </div>
                </Dialog>
            </div>
        );

        return(
            <div className="pageWrapper" >
                <Growl ref={(el) => this.growl = el} />
                <div className="p-grid">
                    <div className="p-col-9 ta-right" style={{position:"absolute", marginTop: -60}}>
                        {this.state.chargedYet ? '' :
                            <ProgressSpinner style={{width: '50px', height: '50px'}}  animationDuration=".5s"/>}
                    </div>
                </div>
                <div className="p-grid">

                    <Card height="height-100">
                        <TabView>
                            <TabPanel header="Alerts">

                                <div className="p-col-12">
                                    <Card>




                                        <DataTable value={this.state.listAlerts} header='Warnings'
                                                   reorderableColumns={true}
                                                   selectionMode="single" paginator={true} rows={30}
                                                   resizableColumns={true}
                                                   selection={this.state.dataTableSelectValue} onSelectionChange={(e) => this.setState({dataTableSelectValue: e.value})}>
                                            <Column selectionMode="single" style={{width:'3em'}}/>
                                            <Column field="id" header="Id" sortable={true} className="ta-right" style={{width:'3em'}}/>
                                            <Column field="name" header="Name" sortable={true} className="ta-right"/>
                                            <Column field="min" header="Min" sortable={true} className="ta-right"/>
                                            <Column field="type" header="Type" sortable={true} className="ta-right"/>
                                            <Column field="message" header="Message" sortable={true} className="ta-right"/>
                                        </DataTable>
                                        {dialogs_alert}

                                        <Toolbar className="p-mb-4 mb-10">
                                            <Button label='Edit' onClick={this.showAlertDialog('editDialogAlert')} />
                                        </Toolbar>


                                    </Card>

                                </div>

                                <div className="p-col-12">
                                    <Card>

                                        <Toolbar className="p-mb-4 mb-10">
                                            <Button label='Search' onClick={this.callListPrincipal} />
                                            <Button label='New' onClick={this.showDialog('newDialog')} />
                                            <Button label='Edit' onClick={this.showDialog('editDialog')} />
                                            <Button label='Delete' className="p-button-danger ta-right" onClick={this.showDialog('deleteDialog')} />
                                        </Toolbar>

                                        <DataTable value={this.state.listRecipient} header='Recipients'
                                                   reorderableColumns={true}
                                                   selectionMode="single" paginator={true} rows={30}
                                                   resizableColumns={true}
                                                   selection={this.state.dataTableSelectValue} onSelectionChange={(e) => this.setState({dataTableSelectValue: e.value})}>
                                            <Column selectionMode="single" style={{width:'3em'}}/>
                                            <Column field="id" header="Id" sortable={true} className="ta-right"/>
                                            <Column field="name" header="Name" sortable={true} className="ta-right"/>
                                            <Column field="phone" header="Phone" sortable={true} className="ta-right"/>
                                            <Column field="level" header="Level" sortable={true} className="ta-right"/>
                                            <Column field="created" header="Created" sortable={true} className="ta-right"/>
                                        </DataTable>
                                        {dialogs}


                                    </Card>

                                </div>



                            </TabPanel>


                        </TabView>
                    </Card>



                </div>

            </div>
        );
    }
}
export default DashboardAlert;
