import React, {Component} from 'react';
import SubMenu from "../shared/SubMenu";
import SubMenuItem from "../shared/SubMenu/Item";
import ReportLevel0 from "./reportLevel0";
//import ReportLevel1 from "./reportLevel1";
//import ReportLevel2 from "./reportLevel2";
//import ReportLevel3 from "./reportLevel3";

class FeeReportLevel0 extends Component {
    constructor() {
        super();
        this.state = {
            opcionMenuSelected: 'level0',
        };
        this.changeHandler = this.changeHandler.bind(this);
    }

    changeHandler = event => {
        this.setState({opcionMenuSelected: event.target.name});
    };

    render() {
        return (
            <div className="pageWrapper">
                {/* Sub Menu */}
                <SubMenu>
                    <SubMenuItem id="menu0"
                                 className={this.state.opcionMenuSelected === 'level0' ? "active" : ''} name="level0"
                                 onClick={this.changeHandler}>
                        Report EWI
                    </SubMenuItem>
                </SubMenu>

                {/* Page Content */}
                {(this.state.opcionMenuSelected === 'level0') && <ReportLevel0/>}

            </div>
        );
    }
}

export default FeeReportLevel0;