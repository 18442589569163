import axios from 'axios';
import {configLogin} from '../../components/utils/constants';
import {timeoutNotificationAlertMins} from '../../components/utils/constants';
import {bmsAuthorizationURL} from '../../components/utils/constants';

export class AuthService {

  loginFecht = (params) => {
    axios.post(`${bmsAuthorizationURL}/oauth/token`, params, configLogin)
        .then(resp => {
          sessionStorage.setItem("access_token",resp.data.access_token);
          localStorage.setItem("refresh_token",resp.data.refresh_token);
          return true;
        }).catch(function (error) {
          console.log(error);
          return false;
        });
  };

  loginService = (username, password) => {

    let params = {
        'username': username,
        'password': password
    };

    let config = {
        headers: {
            'Content-Type': 'application/json',
        }
      };

    return axios.post(`${process.env.REACT_APP_ENDPOINT_OAUTH3}/api/token/`, params, config)
    .then(resp => {
        const token = this.parseJwt(resp.data.access);
      sessionStorage.setItem("access_token",resp.data.access);
      sessionStorage.setItem("expires_date", token.exp);
      sessionStorage.setItem("refresh_token",resp.data.refresh);
    });
  }
  parseJwt (token) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
  }

  refreshToken = () => {
	    console.log( "====> refreshToken()" )
	    let refresh_token = localStorage.getItem("refresh_token");
		let params = new URLSearchParams();
		params.append('grant_type','refresh_token');
		params.append('client_id','reactWebClient');
		params.append('refresh_token',refresh_token);
		return axios.post(`${bmsAuthorizationURL}/oauth/token`, params, configLogin)
		.then(resp => {
		  sessionStorage.setItem("access_token",resp.data.access_token);
		  sessionStorage.setItem("expires_date",resp.data.expires_in*1000 + Date.now());
		  localStorage.setItem("refresh_token",resp.data.refresh_token);
          console.log( "====> refreshToken OK!!!" );
          window.varOkConnected = true;
		}).catch(function (error) {
            window.varOkConnected = false;
        });
  }

  checkSession = () => {
	    let access_token = sessionStorage.getItem("access_token");
	    if(access_token) {
	        let expires_date = sessionStorage.getItem("expires_date");
	        let seconds = (expires_date-Date.now())/1000;
	        let minutes = seconds/60;
            //console.log("=0=> session timeout minutes ? = "+ minutes )
            if( minutes < 0 || !window.varOkConnected ) {
                return false;
            } else if( minutes <= `${timeoutNotificationAlertMins}` ) {
                window.showSessionAlert(this.refreshToken);
                return true;
	        }
        }
        return true;
	}
}
