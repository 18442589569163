import React, { Component } from 'react';
import Card from '../shared/Card';
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {Button} from "primereact/components/button/Button";
import CardTitle from "../shared/Card/Title";
import {HeisenbergService} from "../../service/heisenberg/HeisenbergService";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import { Growl } from 'primereact/growl';
import {InputText} from "primereact/components/inputtext/InputText";
//import {Dialog} from "primereact/components/dialog/Dialog";
//import {TabPanel, TabView} from "primereact/tabview";
import {Toolbar} from "primereact/toolbar";
import {ProgressSpinner} from "primereact/progressspinner";
//import {formatCurrency} from "../utils/commonFunctions";
//import {PaymentService} from "../../service/payment/PaymentService";
import {UsuarioService} from "../../service/login/UsuarioService";
//import ReactCountryFlag from "react-country-flag";
import {Chart} from "primereact/components/chart/Chart";

class ProfileTransactions extends Component {

    constructor(){
        super();
        this.apiHeisenberg = new HeisenbergService()
        this.state ={
            data : new FormData(),
            fileName : "",
            merchantId : "*",
            listMechants : [],
            listTrans: [],
            listProfile: [],
            listAccount: [],
            catBanks:[],
            bankId:"",
            globalFilter: null,
            globalFilter_trans: null,
            dataTableSelectValue: {},
            merchantData: {},
            dataSeriesAccount: {},
            chargedYet: false,
            chargedYet2: true,
            refundDialog: false,
            account_tkn_no: "*",
            detailProfile: false
        }

        this.callListPrincipal = this.callListPrincipal.bind(this);
        this.initCatalogos = this.initCatalogos.bind(this);
        this.actionBodyDetail = this.actionBodyDetail.bind(this);
        this.actionTransactions = this.actionTransactions.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
    }

    componentDidMount() {
        this.initCatalogos();
        this.callListPrincipal();
    }

    initCatalogos(){
        this.apiHeisenberg = new HeisenbergService()

        let lstMerchants = sessionStorage.getItem('lstMerchants');
        this.setState({
            listMechants: JSON.parse(lstMerchants)
        });
    }

    callListPrincipal(){
        this.usuarioService = new UsuarioService()

        this.usuarioService.getReporProfiles().then(resp => {
            if(resp.status === 'success'){
                const listProfile = resp.data

               this.setState({
                   listProfile,
                    chargedYet: true});
                ////this.showSucccess(resp.time_react)

            }else{
                //swal("ERROR", resp.messageResponse, "error");
            }
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });
    }

    actionBodyDetail() {
        this.setState({
            code:"",
            operation:"payment",
            detailProfile: true,
            chargedYet:false,
            chargedYet2:true,
        });

        this.usuarioService = new UsuarioService()
        if ('email' in this.state.dataTableSelectValue){
            let email =this.state.dataTableSelectValue.email
            this.usuarioService.getAccountList(email).then(resp => {
                if(resp.status === 'success'){
                    const listAccount = resp.data

                    this.setState({
                        listAccount,
                        chargedYet: true});

                }else{
                    //swal("ERROR", resp.messageResponse, "error");
                }
            }).catch(error => {
                //swal("ERROR", error.response.data.messageResponse, "error");
            });



        }

        this.actionTransactions()


    }


    actionTransactions() {
        this.setState({
            code:"",
            operation:"payment",
            detailProfile: true,
            chargedYet2:false
        });

        this.usuarioService = new UsuarioService()
        if ('email' in this.state.dataTableSelectValue){
            let email =this.state.dataTableSelectValue.email
            let account_tkn_no =this.state.account_tkn_no
            this.usuarioService.getTransactionList(email,account_tkn_no).then(resp => {
                if(resp.status === 'success'){
                    const listTrans = resp.data

                    this.setState({
                        listTrans,
                        chargedYet2: true});

                }else{
                    //swal("ERROR", resp.messageResponse, "error");
                }
            }).catch(error => {
                //swal("ERROR", error.response.data.messageResponse, "error");
            });

            //Add Sumary
            this.usuarioService.getReportSumaryAccount(email,account_tkn_no).then(resp => {
                if(resp.status === 'success'){
                    const merchantData = resp.report_merchant;
                    const dataSeriesAccount = resp.report_serie;



                    this.setState({merchantData});
                    this.setState({dataSeriesAccount});

                }else{
                    //swal("ERROR", resp.messageResponse, "error");
                }
            }).catch(error => {
                //swal("ERROR", error.response.data.messageResponse, "error");
            });





        }



    }

    /**
     * Metodo para mostrar mensaje de error cuando el usuario y/o password son incorrectos
     * params:
     * return: se habilita mensaje de error
     **/
    showSucccess(msgDetail) {
        let msg = { severity: 'success', summary: 'OK', detail: msgDetail};
        this.growl.show(msg);
    }

    showError(msgDetail) {
        let msg = { severity: 'error', summary: 'ERROR', detail: msgDetail};
        this.growl.show(msg);
    }

    /**
     * Metodo para mostrar mensaje de alerta cuando el usuario y/o password estan vacios
     * params:
     * return: se habilita mensaje de alerta
     **/
    showWarn() {
        let msg = { severity: 'warn', summary: 'AVISO', detail: 'You must specify username and password' };
        this.growl.show(msg);
    }

    /**
     * Metodo para mostrar mensaje de alerta generico
     * params:
     * return: se habilita mensaje de alerta
     **/
    showMsg(severityMsg, summaryMsg, detailMsg){
        let msg = { severity: severityMsg, summary: summaryMsg, detail: detailMsg };
        this.growl.show(msg);
    }

    hideDialog() {
        this.setState({
            refundDialog: false,
        });
    }
    showRefundDialog = () => {
        const {
            dataTableSelectValue
        } = this.state;
        if (dataTableSelectValue.id === undefined) {
            const msg = { severity: 'error', summary: 'You need', detail: 'Select a row to refund'};
            this.growl.show(msg);
        } else {
            this.setState({refundDialog: true});
        }
    }


    render() {
        const header = (
            <div style={{'textAlign':'right'}}>
                <i className="pi pi-search" style={{margin:'4px 4px 0 0'}}></i>
                <InputText type="search" onInput={(e) => this.setState({globalFilter: e.target.value})} placeholder="Global Search" size="50"/>
            </div>
        );

        /*const dialogFooterCancel = <div className="ui-dialog-buttonpane p-clearfix">
            <Button label="Submit" icon="pi pi-check" onClick={this.callAction}/>
        </div>;*/

        /*const refundFooter = (
            <React.Fragment>
                <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Refund/Disputes" icon="pi pi-check" className="p-button-danger" onClick={this.refundAction} />
            </React.Fragment>
        );*/

        return(
            <div className="pageWrapper">

                <Growl ref={(el) => this.growl = el} />
                <div className="p-grid">
                    <div className="p-col-12">
                        <Card>
                            <CardTitle>Accounts</CardTitle>

                            <Toolbar className="p-mb-4 mb-10">
                                <Button label="Search" onClick={this.callListPrincipal} />
                                <Button label="Detail" onClick={this.actionBodyDetail}/>
                            </Toolbar>

                            <DataTable value={this.state.listProfile}
                                       header={header} globalFilter={this.state.globalFilter} reorderableColumns={true}
                                       selectionMode="single" paginator={true} rows={10}
                                       resizableColumns={true}
                                       selection={this.state.dataTableSelectValue} onSelectionChange={(e) => this.setState({dataTableSelectValue: e.value})}>
                                <Column selectionMode="single" style={{width:'3em'}}/>
                                <Column field="email" header="Email" sortable={true} className="ta-right"/>
                                <Column field="total_account" header="Tokens" sortable={true} className="ta-right"/>
                            </DataTable>
                        </Card>
                    </div>
                </div>


                {this.state.detailProfile ?



                <div className="p-grid">
                    <div className="p-col-12">
                        <Card height="height-100" id="dataProfile">
                            <CardTitle>Account Tokens</CardTitle>
                            <div className="p-grid">
                                <div className="p-col-9 ta-right" style={{position:"absolute", marginTop: -60}}>
                                    {this.state.chargedYet2 ? '' :
                                        <ProgressSpinner style={{width: '50px', height: '50px'}}  animationDuration=".5s"/>}
                                </div>
                            </div>
                            <Toolbar className="p-mb-4 mb-10">
                                <Dropdown value={this.state.account_tkn_no} options={this.state.listAccount} onChange={(e) => {
                                    this.setState({account_tkn_no: e.value})
                                }} placeholder="Select Account"
                                          className="mr-5"/>
                                <Button label="Search" onClick={this.actionTransactions} />

                            </Toolbar>

                        </Card>
                    </div>

                    <div className="p-col-8">
                        <Card height="height-50" id="dataProfile">
                            <div className="p-grid p-justify-between p-align-center">
                                <CardTitle className="small">Transactions Serie</CardTitle>
                            </div>

                            <div>
                                <Chart type="line" data={this.state.dataSeriesAccount} height="95"/>
                            </div>
                        </Card>
                    </div>

                    <div className="p-col-4 p-lg-4">
                        <Card height="height-50">
                            <div className="p-grid p-justify-between p-align-center">
                                <CardTitle className="small">Merchants</CardTitle>
                            </div>
                            <div className="p-grid">
                                <div className="p-col-8">
                                    <div>
                                        <Chart type="pie" data={this.state.merchantData} height="300" />
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>


                    <div className="p-col-12">
                        <Card height="height-100">
                            <CardTitle>Transactions</CardTitle>
                            <DataTable value={this.state.listTrans}
                                       header={header} globalFilter={this.state.globalFilter_trans} reorderableColumns={true}
                                       selectionMode="single" paginator={true} rows={10}
                                       resizableColumns={true}>
                                <Column field="transaction_identifier" header="IdTrans" sortable={true} className="ta-right"/>
                                <Column field="card_token" header="Token" sortable={true}/>
                                <Column field="last4_pan" header="last4ofPAN" sortable={true}/>
                                <Column field="merchant_name" header="Merchant" sortable={true}/>
                                <Column field="amount" header="Amount" sortable={true} className="ta-right"/>
                                <Column field="requested_currency" header="Currency" sortable={true} className="ta-right"/>
                                <Column field="program_id" header="ProgramId" sortable={true} className="ta-right"/>
                                <Column field="merchant_country" header="Country" sortable={true} className="ta-right"/>
                                <Column field="transaction_type" header="Type" sortable={true} className="ta-right"/>
                                <Column field="response" header="Response" sortable={true} className="ta-right"/>
                                <Column field="date_created" header="Created" sortable={true} className="ta-right" style={{width:'10em'}}/>
                            </DataTable>

                        </Card>
                    </div>


                </div>



                :''}



            </div>







        );
    }
}

export default ProfileTransactions;
