import axios from 'axios';
//import {configLogin} from '../../components/utils/constants';
import {timeoutNotificationAlertMins} from '../../components/utils/constants';
//import {bmsAuthorizationURL} from '../../components/utils/constants';

export class HeisenbergService {

    constructor(){
        this.state = {

        };

    }

    getConfig = (params) => {
        //this.checkSession()
        //let heisenberg_access_token = sessiopinnStorage.getItem("heisenberg_access_token");
        let heisenberg_access_token = 'wvH2Q7hEebs4GLpdsCLOuoWl6ht2cF';

        return {
            params : params,
            headers : {
                'Authorization': `Bearer ${heisenberg_access_token}`,
            }
        };
    }

    getConfigToken = (params) => {
        const heisenberg_access_token = sessionStorage.getItem("access_token");
        return {
            params : params,
            headers : {
                'Authorization': `Bearer ${heisenberg_access_token}`,
            }
        };
    }


    getAuth() {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/v/1.0/gateways/plaid/Auth`,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    /*getTransactions() {
        console.log("En transaction call de heisenber")
        let params = {
                    "merchant_key": "holamundi",
                    "bank": "BankOfAmerica"
                }
        return axios.post(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/v/1.0/gateways/plaid/Transactions`,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }*/

    /**
     * Api Cards
     */
    getAllCards() {
        console.log("En transaction call all cards")
        return axios.get(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/portal/service/cards/lstCards`,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    getAllLogCardBalance() {
        console.log("En transaction call all cards")
        return axios.get(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/portal/service/cards/logCardBalance`,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    /**
     * Api Providers
     */
    getAllProviders() {
        console.log("En transaction call providers")
        return axios.get(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/portal/service/providers/lstProviders`,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    /**
     * Api Merchants
     */
    getAllMerchants() {
        console.log("En transaction call all merchants")
        return axios.get(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/portal/service/merchants/lstMerchants`,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    /**
     * Api Merchants
     */
    getAllAccounts() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/portal/service/accounts/lstAccounts`,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    getHeadersBalanceBTC() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/v/2.0/service/broker/payment/btc/merchant/sumary/`, this.getConfigToken()).then(
            resp => {
                return resp.data;
            });
    }


    getAllMerchantsUsers() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/portal/service/accounts/lstMerchantUsers`,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    getAllKycUsers() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/portal/service/kyc/listKyc`,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    getAllFeeProviders() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/portal/service/fees/feeProvider/list`,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    getAllDetailFeeProviders(providerId) {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/portal/service/fees/feeProvider/listDetailFee/${providerId}`,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    getListInvoices(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/v/2.0/service/broker/invoice/list/`,params,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    postFeeReport0(params){
        return axios.post(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/v/1.0/service/fees/feeProvider/nivel/`, params,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    getFeeProvidersNivel(lstMerchants,providerFeeId,nivel) {
        let params = {
            "merchant_key": lstMerchants,
            "fee_provider_id": providerFeeId,
            "nivel": nivel,
        }
        return axios.post(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/v/1.0/service/fees/feeProvider/nivel/0/`,params,{responseType: 'blob'}).then(
            resp => {
                return resp.data;
            });
    }

    postInvoiceCreate(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/v/2.0/service/broker/invoice/create/`,params,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    /**
     * Api Apis
     */
    getAllApis() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/portal/service/apis/lstApis`,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    postReportBtcHistoryPayment(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/v/2.0/service/broker/payment/btc/history/`,params,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    postReportBtcHistorySerie(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_HEISENBERGV2}/api/v/1.0/service/rates/btc/series/`,params).then(
            resp => {
                return resp.data;
            });
    }


    getReportBtcSumaryAccount(params) {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/v/2.0/service/broker/payment/btc/account/sumary/`,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    postReportGeneralDonations(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/v/2.0/service/donation/reportes/general/`,params,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    postReportDonationsTrans(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/v/2.0/service/donation/reportes/transactions/`,params,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    postReportDonationsCausas(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/v/2.0/service/donation/reportes/causas/`,params,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    postCreateCausas(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/v/2.0/service/donation/causas/create/`,params,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    putCreateCausas(params,id) {
        return axios.put(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/v/2.0/service/donation/causas/${id}/update/`,params,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    putDeleteCausas(params,id) {
        return axios.put(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/v/2.0/service/donation/causas/${id}/delete/`,params,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    getReportDonationsCausas(merchant_key) {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/v/2.0/service/donation/causas/${merchant_key}/list/`,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    checkSession = () => {
        let access_token = sessionStorage.getItem("heisenberg_access_token");
        console.log("En checksession")
        console.log(access_token)
        if(access_token) {
            let expires_date = sessionStorage.getItem("expires_date");
            let seconds = (expires_date-Date.now())/1000;
            let minutes = seconds/60;
            //console.log("=0=> session timeout minutes ? = "+ minutes )
            if( minutes < 0 || !window.varOkConnected ) {
                return false;
            } else if( minutes <= `${timeoutNotificationAlertMins}` ) {
                window.showSessionAlert(this.refreshToken);
                return true;
            }
        }else {
            this.loginService()
        }

        return true;
    }

    postReportDonationsGeneral(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_HEISENBERG}/api/admin/donation/reportes/general/`, params, this.getConfigToken()).then(
            resp => {
                return resp.data;
            });
    }

}
