import React, {Component} from 'react';
import {InputText} from 'primereact/inputtext';
import {Button} from "primereact/button";
import {changeStyleSheetUrl, validateEmail} from "../util/commonFunctions";
import {Growl} from "primereact/growl";
import {UsuarioService} from "../service/login/UsuarioService";

export default class Forgot_Password extends Component {

    constructor(props) {
        super(props);
        this.state = {
            validando: false,
            email: ''
        }
        this.sendLogin = this.sendLogin.bind(this);
        this.sendResetPasword = this.sendResetPasword.bind(this);
    }

    componentDidMount() {
        changeStyleSheetUrl('layout-css', 'blue-v4', 'layout');
        changeStyleSheetUrl('theme-css', 'blue-v4', 'theme');
    }

    sendLogin = () => {
        this.props.history.push("/login");
    }

    sendResetPasword = () => {
        const self = this;
        this.usuarioService = new UsuarioService();
        if (validateEmail(self.state.email)) {
            self.setState({validando: true})
            this.usuarioService.postForgotPassword(
                {email: self.state.email}).then(resp => {
                self.growl.show({severity: 'success', summary: 'Success', detail: 'An email was sent to the email for password recovery'});
                return setTimeout(function () {
                    self.sendLogin();
                }, 5000);
            }).catch(e => {
                self.setState({validando: false});
                if (self.growl !== null) {
                    self.growl.show({severity: 'error', summary: 'Error', detail: e.message});
                }
            });
        } else {
            self.growl.show({severity: 'error', summary: 'ERROR', detail: "email not valid"});
        }
    }

    render() {
        return (
            <div className="login-body" style={{backgroundColor: "#00283A"}}>
                <Growl ref={(el) => this.growl = el} style={{marginTop: '75px'}}/>
                <div style={{marginTop: "10%"}}>
                    <div className="p-grid card" style={{backgroundColor: "#00283A"}}>
                        <div className="p-col-6 p-offset-3 ta-center" style={{backgroundColor: "#00283A"}}>
                            <img src="assets/layout/images/logo_caribmall2.png" style={{maxWidth: '100%', width: '400px'}}
                                 onClick={this.sendLogin} className="hand-icon" alt="logo"/>
                        </div>
                    </div>
                </div>
                <div className="card login-panel p-fluid">
                    <div className="login-panel-content">
                        <div className="p-grid">
                            <div className="p-col-3" style={{textAlign: 'left'}}>
                                <img src="assets/layout/images/login/icon-login.svg" alt="avalon-react"/>
                            </div>
                            <div className="p-col-9" style={{textAlign: 'left'}}>
                                <h2 className="welcome-text" style={{marginTop: 15}}>Carib Mall</h2>
                                <span className="guest-sign-in">Send email for recovery password</span>
                            </div>
                            <div className="p-col-12" style={{textAlign: 'left'}}>
                                <label className="login-label">Username</label>
                                <div className="login-input">
                                    <InputText value={this.state.email} placeholder="email"
                                               onChange={(e) => this.setState({email: e.target.value})}/>
                                </div>
                            </div>
                            <div className="p-col-12 p-md-6 button-pane">
                                <Button label="Send email"
                                        className={this.state.validando ? 'secondary-btn' : "p-button-primary"}
                                        disabled={this.state.validando}
                                        onClick={this.sendResetPasword}/>
                            </div>
                            <div className="p-col-12 p-md-6 button-pane">
                                <Button label="Cancel"
                                        className={this.state.validando ? 'secondary-btn' : "p-button-secondary"}
                                        disabled={this.state.validando}
                                        onClick={this.sendLogin}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
