import React, { Component } from 'react';
import Card from '../shared/Card';
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {Button} from "primereact/components/button/Button";
import CardTitle from "../shared/Card/Title";
import {HeisenbergService} from "../../service/heisenberg/HeisenbergService";
import { Growl } from 'primereact/growl';
import {InputText} from "primereact/components/inputtext/InputText";
import {Dialog} from "primereact/components/dialog/Dialog";
import {Toolbar} from "primereact/toolbar";
import {ProgressSpinner} from "primereact/progressspinner";
import {UsuarioService} from "../../service/login/UsuarioService";
import {STATUS_TOKEN_CHOICES} from "../utils/constants";
import {InputMask} from "primereact/inputmask";
import {Dropdown} from "primereact/components/dropdown/Dropdown";

class Transactions extends Component {
    user_name;

    constructor(){
        super();
        this.apiHeisenberg = new HeisenbergService()
        this.state ={
            data : new FormData(),
            fileName : "",
            merchantId : "*",
            listMechants : [],
            listTrans: [],
            catBanks:[],
            bankId:"",
            globalFilter: null,
            dataTableSelectValue: {payment_desc:{}},
            chargedYet: false,
            detailDialog: false,
            balance_tpp: 0.0,
            previous:'',
            next:'',
            cardToken:'',
            totalRows:0,
            page:0,
            pageSize:0,
            codeDialog:false,
            pendingCode:'',
            detailPendingTransactionDialog: false,

            status_token_ewi:'Without card'
        }

        this.callListPrincipal = this.callListPrincipal.bind(this);
        this.initCatalogos = this.initCatalogos.bind(this);
        this.actionBodyDetail = this.actionBodyDetail.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.getBalanceCardToken = this.getBalanceCardToken.bind(this);
        this.callListPage = this.callListPage.bind(this);
        this.callListPageNext = this.callListPageNext.bind(this);
        this.callListPagePrevious = this.callListPagePrevious.bind(this);
        this.callListByToken = this.callListByToken.bind(this);
        this.allTransactionsDialog = this.allTransactionsDialog.bind(this);
        this.sendCardBalance = this.sendCardBalance.bind(this);
        this.blockCard = this.blockCard.bind(this);
        this.stolenCard = this.stolenCard.bind(this);
        this.sendCardBalance = this.sendCardBalance.bind(this);
        this.getStatusCardDetail = this.getStatusCardDetail.bind(this);
        this.sendCode = this.sendCode.bind(this);
        this.accountsProfile = this.accountsProfile.bind(this);
        this.declinePending = this.declinePending.bind(this);
        this.searchTransaction = this.searchTransaction.bind(this);
        this.loadProfile = this.loadProfile.bind(this);
    }

    componentDidMount() {
        this.initCatalogos();
        this.callListPrincipal();
        this.accountsProfile();
        this.loadProfile();
    }

    initCatalogos(){
        this.apiHeisenberg = new HeisenbergService()

        let lstMerchants = sessionStorage.getItem('lstMerchants');
        this.setState({
            listMechants: JSON.parse(lstMerchants)
        });
    }

    accountsProfile() {
        const self = this;
        const accounts = [];
        self.usuarioService.getUsuarioLogueo().then(resp => {
            const email_profile = resp.data.username;
            const account = resp.data.accounts;
            accounts.push(account);
            this.setState({accounts, email_profile})
        }).catch(error => {
        });
    }

    callListPrincipal(){
        this.setState({chargedYet: false});
        this.usuarioService = new UsuarioService();
        this.usuarioService.getUsuarioLogueo().then(resp => {
            const profile = JSON.stringify(resp.data);
            let role = resp.data.profile.rol;
            this.setState({login: true});
            sessionStorage.setItem('userNameSesion', this.state.username);
            sessionStorage.setItem('role', role);
            localStorage.setItem('profile', profile);
            this.menu = [];
            this.setState({typeUsser: role});
            if (role === 'root') {
                this.usuarioService.getReportWebHookTransProd().then(resp => {
                    const listTrans = resp.data;
                    this.setState({
                        listTrans,
                        chargedYet: true
                    });
                }).catch(error => {
                    this.setState({chargedYet: true});
                    //swal("ERROR", error.response.data.messageResponse, "error");
                });
            } else {
                this.usuarioService.getListTranactionsAgent().then(resp => {
                    const listTrans = resp.data;
                    this.setState({
                        listTrans,
                        chargedYet: true
                    });
                }).catch(error => {
                    this.setState({chargedYet: true});
                    //swal("ERROR", error.response.data.messageResponse, "error");
                });
            }
        });
    }

    callListByToken(){
        this.usuarioService = new UsuarioService()

        this.usuarioService.getReportWebHookTransByToken(this.state.cardToken).then(resp => {
            const listTrans = resp.data;
            const previous = '';
            const next = '';
            this.setState({
                listTrans,previous,next,
                chargedYet: true});


        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });
    }

    callListPageNext(){
        this.setState({chargedYet: true});
        this.callListPage(this.state.next)
    }

    callListPagePrevious(){
        this.setState({chargedYet: true});
        this.callListPage(this.state.previous)
    }

    callListPage(link){
        console.log('callListPage')
        if (link.length>0 && ! link.includes('localhost')) {
            link = link.replace('http','https')
        }
        console.log(link)

        this.usuarioService = new UsuarioService()

        this.usuarioService.getReportWebHookTransPage(link).then(resp => {
            const listTrans = resp.results;
            const previous = resp.links.previous;
            const next = resp.links.next;
            const totalRows= resp.total;
            const page= resp.page;
            const pageSize= resp.page_size;
            this.setState({
                listTrans,previous,next,totalRows,page,pageSize,
                chargedYet: true});




        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });
    }

    actionBodyDetail() {
        //this.getBalanceCardToken();
        //this.getStatusCardDetail();
        this.setState({
            code:"",
            cardToken:"",
            operation:"payment",
            detailDialog: true,
            balance_tpp:0.0
        });
    }

    getBalanceCardToken(){
        this.setState({chargedYet: false})
        this.usuarioService = new UsuarioService()
        let params = {token :this.state.dataTableSelectValue.cardToken}
        this.usuarioService.postBalanceCardTokenTpp(params).then(resp => {
            if(resp.status === 'success'){
                const balance = resp.data.Balance / 100;
                const balance_tpp = balance.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                this.setState({
                    balance_tpp,
                    chargedYet: true});
                //this.showSucccess(resp.time_react)
            }else{
                //swal("ERROR", resp.messageResponse, "error");
            }
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });
    }

    getStatusCardDetail(){
        const status_token_ewi = 'Without card';
        this.usuarioService = new UsuarioService();
        let params = {card_token: this.state.dataTableSelectValue.cardToken}
        this.usuarioService.postTokenStatusCardDetail(params).then(resp => {
            if(resp.code === 200){
                const data = resp.data;
                const status_token_ewi = data.status_token_ewi === 1 ? 'BLOCK_CARD' : data.status_token_ewi === 2 ? 'STOLEN_CARD' : 'ACTIVE';
                this.setState({status_token_ewi});
            }else if(resp.code === 204){
                this.setState({status_token_ewi});
            }else {
                this.setState({status_token_ewi});
            }
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });
    }

    /**
     * Metodo para mostrar mensaje de error cuando el usuario y/o password son incorrectos
     * params:
     * return: se habilita mensaje de error
     **/
    showSucccess(msgDetail) {
        let msg = { severity: 'success', summary: 'OK', detail: msgDetail};
        this.growl.show(msg);
    }

    showError(msgDetail) {
        let msg = { severity: 'error', summary: 'ERROR', detail: msgDetail};
        this.growl.show(msg);
    }

    /**
     * Metodo para mostrar mensaje de alerta cuando el usuario y/o password estan vacios
     * params:
     * return: se habilita mensaje de alerta
     **/
    showWarn() {
        let msg = { severity: 'warn', summary: 'AVISO', detail: 'You must specify username and password' };
        this.growl.show(msg);
    }

    /**
     * Metodo para mostrar mensaje de alerta generico
     * params:
     * return: se habilita mensaje de alerta
     **/
    showMsg(severityMsg, summaryMsg, detailMsg){
        let msg = { severity: severityMsg, summary: summaryMsg, detail: detailMsg };
        this.growl.show(msg);
    }

    hideDialog() {
        this.setState({
            detailDialog: false,
            detailPendingTransactionDialog: false
        });
    }

    allTransactionsDialog() {
        const selectFrom = this.state.accounts[0];
        if (this.state.dataPendingTrans.balance_final_agent > 0) {
            this.setState({chargedYet: false});
            this.setState({chargedYet: false});
            let params;
            if (selectFrom.label === "Master Wallet") {
                params = {
                    account_id: this.state.dataTableSelectValue.account_id,
                    username: this.state.dataTableSelectValue.user_name,
                    pending_id: this.state.dataTableSelectValue.id,
                    code: this.state.pendingCode,
                    agent_username: null,
                    agent_account_id: null
                };
            } else {
                params = {
                    account_id: this.state.dataPendingTrans.account_id,
                    username: this.state.dataPendingTrans.user_name,
                    pending_id: this.state.dataPendingTrans.id,
                    code: this.state.dataPendingTrans.code,
                    agent_username: this.state.email_profile,
                    agent_account_id: selectFrom.provider_accountid,
                    agent_fee: this.state.agent_fee
                };
            }
            this.setState({
                detailDialog: false,
                detailPendingTransactionDialog: false
            });
            this.usuarioService = new UsuarioService();
            this.usuarioService.postPendingAuthorize(params).then(resp => {
                const previous = '';
                const next = '';
                this.setState({
                    seacrhCodeDialog: false,
                    selectAcoount: null,
                    previous, next,
                    codeDialog: false,
                    chargedYet: true,
                    pendingCode: '',
                    detailDialog: false,
                    detailPendingTransactionDialog: false
                });
                let msg = {severity: 'success', summary: 'Success'};
                this.growl.show(msg);
                this.callListPrincipal();
            }).catch(error => {
                if (error.response.data.Mgs) {
                    this.growl.show({severity: 'error', summary: 'Error', detail: error.response.data.Mgs});
                } else if (error.response.data.data) {
                    this.growl.show({severity: 'error', summary: 'Error', detail: error.response.data.data});
                } else {
                    this.growl.show({severity: 'error', summary: 'Error'});
                }
                this.setState({
                    seacrhCodeDialog: false,
                    detailDialog: false,
                    detailPendingTransactionDialog: false,
                    selectAcoount: null,
                    chargedYet: true,
                    pendingCode: '',
                    codeDialog: false
                });
            });
        } else {
            this.growl.show({severity: 'info', summary: 'Info', detail: 'Agent balance is not enough'});
        }
    }

    sendCode() {
        this.setState({
            codeDialog: true
        })
    }

    declinePending() {
        this.setState({ detailDialog: false, chargedYet: true });
        let params = {
            agent_username: this.state.email_profile,
            account_id: this.state.dataPendingTrans.account_id,
            username: this.state.dataPendingTrans.user_name,
            pending_id: this.state.dataPendingTrans.id,
            reference: "Cancel",
        };
        this.usuarioService = new UsuarioService();
        this.usuarioService.postPendingDecline(params).then(resp => {
            const previous = '';
            const next = '';
            this.setState({
                selectAcoount: null,
                previous,next,
                codeDialog: false,
                chargedYet: true,
                seacrhCodeDialog: false,
                detailDialog: false,
                detailPendingTransactionDialog: false,
            });
            let msg = { severity: 'success', summary: 'Success', detail: resp.data.data};
            this.growl.show(msg);
            this.callListPrincipal();
        }).catch(error => {
            this.growl.show({severity: 'error', summary: 'Error', detail: error.response.data.data});
            this.setState({
                selectAcoount: null,
                chargedYet: true,
                pendingCode: '',
                seacrhCodeDialog: false,
                detailDialog: false,
                detailPendingTransactionDialog: false,
            });
        });
    }

    sendCardBalance() {
        this.props.transactionSelected(this.state.dataTableSelectValue);
    }

    showRefundDialog = () => {
        const {
            dataTableSelectValue
        } = this.state;
        if (dataTableSelectValue.id === undefined) {
            const msg = { severity: 'error', summary: 'You need', detail: 'Select a row to refund'};
            this.growl.show(msg);
        } else {
            this.setState({refundDialog: true});
        }
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    blockCard(){
        let params = {
            card_token: this.state.dataTableSelectValue.cardToken,
            status: STATUS_TOKEN_CHOICES.BLOCK_CARD
        }
        console.log("Block Card params: ", params);
        this.usuarioService = new UsuarioService()
        this.usuarioService.postTokenStatusCard(params).then(resp => {
            if (resp.code === 200) {
                this.setState({detailDialog: false});
                this.showSucccess("Block Card success!");
            } else if (resp.code === 204) {
                this.showError(`${resp.message}!`);
            } else {
                this.showError("Service error!");
            }
        }).catch(error => {
            console.error("postTokenStatusCard: ",error);
        });

    }
    stolenCard(){
        let params = {
            card_token: this.state.dataTableSelectValue.cardToken,
            status: STATUS_TOKEN_CHOICES.STOLEN_CARD
        }
        console.log("Stolen Card params: ", params);
        this.usuarioService = new UsuarioService();
        this.usuarioService.postTokenStatusCard(params).then(resp => {
            if (resp.code === 200) {
                this.setState({detailDialog: false});
                this.showSucccess("Block Card success!");
            } else if (resp.code === 204) {
                this.showError(`${resp.message}!`);
            } else {
                this.showError("Service error!");
            }
        }).catch(error => {
            console.error("postTokenStatusCard: ",error);
        });
    }

    statusCashTemplate = (rowData, column) => {
        let statusInfo = this.getStatusCashTextAndColor(rowData.status_cash);
        return (
            <div style={{ backgroundColor: statusInfo.color, textAlign: 'center', padding: '5px', color: 'black', borderRadius: '8px' }}>
                {statusInfo.text}
            </div>
        );
    }

    getStatusCashTextAndColor = (statusCash) => {
        switch (statusCash) {
            case 1:
                return { text: "Pending", color: "yellow" };
            case 2:
                return { text: "Success", color: "#7bc564" };
            case 3:
                return { text: "Error", color: "red" };
            case 4:
                return { text: "Closed", color: "#ff9438" };
            default:
                return { text: "", color: "white" };
        }
    }

    handleButtonClick = () => {
        this.setState({ seacrhCodeDialog: true });
    }

    searchTransaction() {
        const profile = JSON.parse(localStorage.getItem('profile'));
        let agent_account_id = profile.accounts.provider_accountid
        let code = this.state.pendingCode
        let params = {
            code: code,
            agent_account_id: agent_account_id
        };
        this.usuarioService.postSearchTransactionCode(params).then(resp => {
            let dataPendingTrans = resp.data.data[0];
            let fee_extra_agent = resp.data.fee_extra_agent
            let fee_extra_country = resp.data.fee_extra_country
            this.setState({
                fee_extra_agent,
                detailPendingTransactionDialog: true,
                fee_extra_country,
                dataPendingTrans,
                chargedYet: true,
            });
            let msg = { severity: 'success', summary: 'Success', detail: resp.status};
            this.growl.show(msg);
        }).catch(error => {
            let msg = { severity: 'error', summary: 'Error', detail: error.response};
            this.growl.show(msg);
            this.setState({
                chargedYet: true,
                pendingCode: '',
            });
        });
    }
    loadProfile() {
        const self = this;
        this.usuarioService = new UsuarioService();
        self.usuarioService.getUsuarioLogueo().then(resp => {
            let agent_fee = resp.data.agent_find.extern_fee
            this.setState({agent_fee});
        }).catch(error => {
        });
    }



    render() {


        /* const dialogFooterCancel = <div className="ui-dialog-buttonpane p-clearfix">
             <Button label="Submit" icon="pi pi-check" onClick={this.callAction}/>
         </div>;*/
        const formatCurrency = (field) => {
            return (rowData) => parseFloat(rowData[field]).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        };
        const dateTemplate = (rowData) => {
            let [date] = rowData.date.split(" ");
            return <span>{date}</span>;
        };

        const refundFooter = (
            <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                <div>
                {/*<Button label="Block card" className="p-button-warning" onClick={this.blockCard} />
                <Button label="Stolen card" className="p-button-danger" onClick={this.stolenCard} />*/}
                <Button label="Approve" className="p-button-text" onClick={this.allTransactionsDialog}/>
                <Button label="Decline" icon="pi pi-directions" className="p-button-info"
                        onClick={this.declinePending}/>
                <Button label="Close" icon="pi pi-times" className="p-button-secondary" onClick={this.hideDialog}/>
                </div>
                <div>
                    {/*    <label style={{marginRight: '10px'}}>Enter fee: </label>
                    <InputText style={{width: '200px'}} value={this.state.agent_fee}
                               onChange={(e) => this.setState({agent_fee: e.target.value})}/>*/}
                </div>
            </div>
        );


        const pendingCodeDialogFooter = (
            <React.Fragment>
                <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={() => { this.setState({ seacrhCodeDialog: false }); }}/>
                {this.state.pendingCode && <Button label="Seach" icon="pi pi-check" className="p-button-text" onClick={this.searchTransaction}/>}            </React.Fragment>
        );
        const pendingCode = (
            <Dialog header="Code" visible={this.state.codeDialog} style={{height: '300px', background: "#1c2155"}}
                    contentStyle={{height: '250px', background: "#FFF"}} footer={pendingCodeDialogFooter}
                    modal className="p-fluid" onHide={() => {
                this.setState({codeDialog: false});
            }}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <div className="p-field" style={{width: '100%', textAlign: 'center',marginBottom: '20px'}}>
                        <label>From:</label>
                        <Dropdown
                            optionLabel="label"
                            style={{padding: '1px', width: '100%'}}
                            value={this.state.selectAcoount}
                            options={this.state.accounts}
                            onChange={(e) => {
                                this.setState({selectAcoount: e.value})
                            }}
                            className="mr-5"
                        />
                    </div>
                    <div className="p-field" style={{marginBottom: '10px', textAlign: 'center'}}>
                        <label>Code:</label>
                        <InputMask
                            style={{textAlign: 'center'}}
                            className="text-center"
                            tooltip="Enter code"
                            mask="99-99"
                            value={this.state.pendingCode}
                            placeholder="99-99"
                            onChange={(e) => {
                                this.setState({
                                    pendingCode: e.target.value
                                });
                            }}
                        />
                    </div>
                </div>
            </Dialog>
        );
        const searchCode = (
            <Dialog header="Search Code" visible={this.state.seacrhCodeDialog} style={{height: '300px', background: "#1c2155"}}
                    contentStyle={{height: '250px', background: "#FFF"}} footer={pendingCodeDialogFooter}
                    modal className="p-fluid" onHide={() => {
                this.setState({seacrhCodeDialog: false, pendingCode: ''});
            }}>
                <div className="p-field" style={{marginBottom: '10px', textAlign: 'center'}}>
                    <label style={{fontWeight: 'bold', marginRight: '10px'}}>Enter Code:</label>
                    <InputText
                        style={{textAlign: 'center'}}
                        className="text-center"
                        tooltip="Enter code"
                        value={this.state.pendingCode}
                        placeholder="aa-9999"
                        onChange={(e) => {
                            this.setState({
                                pendingCode: e.target.value
                            });
                        }}
                    />

                </div>
            </Dialog>
        );

        let header = (
            <div style={{'textAlign': 'left'}}>
                <i className="pi pi-search" style={{margin: '4px 4px 0 0'}}></i>
                <InputText type="search" onInput={(e) => this.setState({globalFilter: e.target.value})}
                           placeholder="Global Search" size="50"/>
            </div>
        );

        return (
            <div className="pageWrapper">
                <Growl ref={(el) => this.growl = el}/>
                <Card>
                    <CardTitle>Cash Transactions</CardTitle>
                    <div className="p-grid">
                        <div className="p-col-9 ta-right" style={{position: "absolute", marginTop: -60}}>
                        {this.state.chargedYet ? '' :
                                <ProgressSpinner style={{width: '50px', height: '50px'}} animationDuration=".5s"/>}
                        </div>
                    </div>

                    <Toolbar className="p-mb-4 mb-10">
                        <Button style={{marginLeft:'10px'}} label="Reload" onClick={this.callListPrincipal} />
                        <Button style={{marginLeft:'10px'}} label="Search Transaction By Code" onClick={this.handleButtonClick} />
                    </Toolbar>

                    <Toolbar className="p-mb-4 mb-10">
                        {/*<Button label="Reset" onClick={this.callListPrincipal} />*/}
                        <Button label="Detail" onClick={this.actionBodyDetail}/>
                        <Button label="<<" onClick={this.callListPagePrevious} />
                        <label htmlFor="page">Page {this.state.page}  </label>
                        <Button label=">>" onClick={this.callListPageNext}/>
                        <label htmlFor="total">Total {this.state.totalRows}  </label>
                    </Toolbar>

                    <div style={{width:'100%', height:'500px', overflow: 'scroll'}}>
                        <DataTable     value={this.state.listTrans}
                                       scrollable
                                       style={{width: '2500px'}}
                                       resizableColumns={true}
                                       reorderableColumns={true}
                                       header={header}
                                       globalFilter={this.state.globalFilter}
                                       selectionMode="single"
                                       paginator={true}
                                       rows={1500}
                                       selection={this.state.dataTableSelectValue}
                                       onSelectionChange={(e) => this.setState({dataTableSelectValue: e.value})}>
                            {/*<Column selectionMode="single" style={{width:'5%'}}/>
                            <Column field="date" header="Date" sortable={true} body={rowData => this.colorizeCell(rowData, {field: 'date'})} className="ta-center" style={{width:'8%', }}/>
                            <Column field="code" header="Code" sortable={true} className="ta-center" style={{width:'5%'}} body={rowData => this.colorizeCell(rowData, {field: 'code'})}/>
                            <Column field="user_name" header="User Name" sortable={true}  body={rowData => this.colorizeCell(rowData, {field: 'user_name'})} className="ta-center" style={{width:'15%'}}/>
                            <Column field="desc" header="Desc" sortable={true} className="ta-center" style={{width:'15%'}} body={rowData => this.colorizeCell(rowData, {field: 'desc'})}/>
                            <Column field="amount" header="Amount" sortable={true}  className="ta-center" style={{width:'8%'}} body={rowData => this.colorizeCell(rowData, {field: 'amount'})}/>
                            <Column field="balance" header="Balance" sortable={true} className="ta-center" style={{width:'8%'}} body={rowData => this.colorizeCell(rowData, {field: 'balance'})}/>
                            <Column field="load_amount" header="Load Amount" sortable={true}  className="ta-center" style={{width:'8%'}} body={rowData => this.colorizeCell(rowData, {field: 'load_amount'})}/>
                            <Column field="load_balance" header="Load Balance" sortable={true}  className="ta-center" style={{width:'8%'}} body={rowData => this.colorizeCell(rowData, {field: 'load_balance'})}/>
                            <Column field="status_cash" header="Status Cash" sortable={true} className="ta-center" style={{width:'12%'}} body={rowData => this.colorizeCell(rowData, {field: 'status_cash'})}/>
                            <Column field="status_cash_desc" header="Status Cash Desc" sortable={true} className="ta-center" style={{width:'17%'}} body={rowData => this.colorizeCell(rowData, {field: 'status_cash_desc'})}/>
                            <Column field="d_transaction_update" header="Transaction Update" sortable={true} className="ta-center" style={{width:'13%'}} body={rowData => this.colorizeCell(rowData, {field: 'd_transaction_update'})}/>
                            <Column field="d_transaction_time" header="Transaction Ttime" sortable={true} className="ta-center" style={{width:'7%'}} body={rowData => this.colorizeCell(rowData, {field: 'd_transaction_time'})}/>
                            <Column field="load_date" header="Load Date" sortable={true} className="ta-center" style={{width:'8%'}} body={rowData => this.colorizeCell(rowData, {field: 'load_date'})}/>
                            <Column field="load_desc" header="Load Desc" sortable={true} className="ta-center" style={{width:'10%'}} body={rowData => this.colorizeCell(rowData, {field: 'load_desc'})}/>
                            <Column field="load_reference" header="Load Reference" sortable={true} className="ta-center" style={{width:'9%'}} body={rowData => this.colorizeCell(rowData, {field: 'load_reference'})}/>*/}

                            <Column field="status_cash" header="Status" sortable={true} className="ta-center" body={this.statusCashTemplate} style={{width:'4%'}}/>
                            <Column field="date" header="Date" sortable={true} body={dateTemplate} className="ta-center" style={{width:'6%'}}/>
                            <Column field="user_name" header="User Name" sortable={true} className="ta-center" style={{width:'11%'}}/>
                            <Column field="code" header="Code" sortable={true} className="ta-center" style={{width:'5%'}}/>
                            <Column field="desc" header="Desc" sortable={true} className="ta-center" style={{width:'10%'}}/>
                            <Column field="amount" header="Amount" sortable={true} body={formatCurrency('amount')} className="ta-center" style={{width:'8%'}}/>
                            {/*<Column field="balance" header="Balance" sortable={true} body={formatCurrency('balance')}className="ta-center" style={{width:'8%'}}/>*/}
                            <Column field="load_amount" header="Load Amount" sortable={true} body={formatCurrency('load_amount')} className="ta-center" style={{width:'8%'}}/>
                            <Column field="load_balance" header="Load Balance" sortable={true} body={formatCurrency('load_balance')} className="ta-center" style={{width:'8%'}}/>
                            <Column field="agent" header="Agent" sortable={true} className="ta-center" style={{width:'10%'}}/>
                            {/*<Column field="status_cash" header="Status Cash" sortable={true} className="ta-center" style={{width:'12%'}}/>*/}
                            {/*<Column field="status_cash_desc" header="Status Cash Desc" sortable={true} className="ta-center" style={{width:'17%'}}/>*/}
                            <Column field="d_transaction_update" header="Transaction Update" sortable={true} className="ta-center" style={{width:'13%'}}/>
                            <Column field="d_transaction_time" header="Transaction Ttime" sortable={true} className="ta-center" style={{width:'7%'}}/>
                            <Column field="load_date" header="Load Date" sortable={true} className="ta-center" style={{width:'8%'}}/>
                            <Column field="load_desc" header="Load Desc" sortable={true} className="ta-center" style={{width:'10%'}}/>
                            <Column field="load_reference" header="Load Reference" sortable={true} className="ta-center" style={{width:'9%'}}/>
                            </DataTable>

                    </div>




                    <Toolbar className="p-mb-4 mb-10">
                        <Button label="Reset" onClick={this.callListPrincipal} />
                        <Button label="Detail" onClick={this.actionBodyDetail}/>
                        <Button label="<<" onClick={this.callListPagePrevious} />
                        <label htmlFor="page">Page {this.state.page}  </label>
                        <Button label=">>" onClick={this.callListPageNext}/>
                        <label htmlFor="total">Total {this.state.totalRows}  </label>
                    </Toolbar>




                    <Dialog visible={this.state.detailDialog} style={{height: '220px'}}
                            header="Detail Transaction" modal className="p-fluid"
                            onHide={this.hideDialog}>
                        <div className="datail-transaction">

                            <p>Date:&nbsp; </p>
                            <span>{this.state.dataTableSelectValue && this.state.dataTableSelectValue.date ?
                                this.state.dataTableSelectValue.date.split(" ")[0] : ''}</span>
                            <p>User Name:&nbsp; </p> <span>{this.state.dataTableSelectValue.user_name}</span>
                            <p>Code:&nbsp; </p> <span>{this.state.dataTableSelectValue.code}</span>
                            <br/>
                            <p>Amount:&nbsp; </p>
                            <span>{parseFloat(this.state.dataTableSelectValue.amount).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD'
                            })}</span>
                            <p>Load Amount:&nbsp; </p>
                            <span>{parseFloat(this.state.dataTableSelectValue.load_amount).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD'
                            })}</span>
                            <p>Load Balance:&nbsp; </p>
                            <span>{parseFloat(this.state.dataTableSelectValue.load_balance).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD'
                            })}</span>
                            <br/>
                            {/*<p>Balance: </p> <span>{parseFloat(this.state.dataTableSelectValue.balance).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>*/}
                        </div>
                    </Dialog>
                    {this.state.dataPendingTrans && (
                    <Dialog visible={this.state.detailPendingTransactionDialog} style={{height: '220px'}}
                            header="Detail Transaction" modal className="p-fluid" footer={refundFooter}
                            onHide={this.hideDialog}>
                        <div className="datail-transaction">
                            <p>Date: &nbsp;</p>
                            <span>{this.state.dataPendingTrans.date.split(" ")[0]}</span>
                            <p>User Name: &nbsp;</p> <span>{this.state.dataPendingTrans.user_name}</span>
                            <p>Code: &nbsp;</p> <span>{this.state.dataPendingTrans.code}</span>
                            <br/>
                            {/*<p>Amount: &nbsp;</p>
                            <span>{parseFloat(this.state.dataPendingTrans.amount).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD'
                            })}</span>*/}
                            <p>Beginning Balance Agent: &nbsp;</p>
                            <span>{parseFloat(this.state.dataPendingTrans.balance_wallet_agent).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD'
                            })}</span>
                            <p>Load Amount: &nbsp;</p>
                            <span>{parseFloat(this.state.dataPendingTrans.load_amount).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD'
                            })}</span>
                            {/*<p>Load Balance: &nbsp;</p>
                            <span>{parseFloat(this.state.dataPendingTrans.load_balance).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD'
                            })}</span>*/}
                            <p>Wallet Load Fee: &nbsp;</p>
                            <span>{parseFloat(this.state.dataPendingTrans.estimate_fee).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD'
                            })}</span>

                            <br/>
                            <p>Ending Balance Agent: &nbsp;</p>
                            <span>{parseFloat(this.state.dataPendingTrans.balance_final_agent).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD'
                            })}</span>
                            <p>Fee Extra Agent: &nbsp;</p>
                            <span>{parseFloat(this.state.fee_extra_agent).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD'
                            })}</span>
                            <p>Country: &nbsp;</p>
                            <span>{this.state.fee_extra_country}</span>

                            {/*<p>Balance: </p> <span>{parseFloat(this.state.dataTableSelectValue.balance).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>*/}
                        </div>
                    </Dialog>)}


                </Card>
                {pendingCode}
                {searchCode}
            </div>
        );
    }
}

export default Transactions;
