import React, { Component } from 'react';
import DashboardAlert from "./dashboardAlert";

class ListAgents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            opcionMenuSelected:'Alerts',
        }

        this.showBox = this.showBox.bind(this);
    }

    showBox = data => () => {
        this.setState({opcionMenuSelected: data});
    }

    render() {
        return (
            <div className="dashboard-banking">

                <div className="p-grid overview-boxes">
                    <div className="p-col-12 p-md-4 p-lg-4" onClick={this.showBox('Alerts')} style={{cursor:'pointer'}}>
                        <div className="overview clearfix">
                            <div className="overview-icon">
                                <img
                                    src="assets/layout/images/dashboard-banking/icons8-agente.png"
                                    alt="avalon-layout"
                                    className="icon-transfer"
                                    style={{
                                        borderRadius: "50%",
                                        height: "41px",
                                        width: "40px",
                                        paddingTop: "4px"
                                    }}
                                />
                            </div>

                            <div className="overview-text">
                                <span className="overview-title"></span>
                                <span className="overview-subtitle">Fee Agents</span>

                            </div>
                        </div>
                    </div>

                </div>

                {/* Page Content */}
                {(this.state.opcionMenuSelected==='Alerts') && <DashboardAlert />}
            </div>
        );
    }
}

export default ListAgents;
