import React, { Component } from 'react';
import Card from '../shared/Card';
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {Button} from "primereact/components/button/Button";
import CardTitle from "../shared/Card/Title";
import {HeisenbergService} from "../../service/heisenberg/HeisenbergService";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {Growl} from 'primereact/growl';
import {InputText} from "primereact/components/inputtext/InputText";
import {Toolbar} from "primereact/toolbar";
import {ProgressSpinner} from "primereact/progressspinner";
import {formatCurrency} from "../utils/commonFunctions";
import {UsuarioService} from "../../service/login/UsuarioService";

class Causas extends Component {

    constructor(){
        super();
        this.apiHeisenberg = new HeisenbergService()
        this.usuarioService = new UsuarioService()
        this.state ={
            data : new FormData(),
            fileName : "",
            merchantId : "*",
            listMechants : [],
            listPayments: [],
            catBanks:[],
            bankId:"",
            globalFilter: null,
            chargedYet: false
        }

        this.callListPrincipal = this.callListPrincipal.bind(this);
        this.initCatalogos = this.initCatalogos.bind(this);
    }

    componentDidMount() {
        this.initCatalogos();
        this.callListPrincipal();
    }

    initCatalogos(){

        let lstMerchants = sessionStorage.getItem('lstMerchants');

        this.setState({
            listMechants: JSON.parse(lstMerchants)
        });



    }

    callListPrincipal(){
        this.usuarioService = new UsuarioService()
        let params = {merchant_key:this.state.merchantId}

        this.usuarioService.postReportDonationsCausas(params).then(resp => {
            if(resp.status === 'success'){
                const listPayments = resp.data
                listPayments.forEach(element => {
                    element.amount = formatCurrency(element.amount);
                    element.fee = formatCurrency(element.fee);
                });

                this.setState({listPayments,
                    chargedYet: true});
                this.showSucccess(resp.time_react)

            }else{
                //swal("ERROR", resp.messageResponse, "error");
            }
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });
    }

    /**
     * Metodo para mostrar mensaje de error cuando el usuario y/o password son incorrectos
     * params:
     * return: se habilita mensaje de error
     **/
    showSucccess(msgDetail) {
        let msg = { severity: 'success', summary: 'OK', detail: msgDetail};
        this.growl.show(msg);
    }

    showError(msgDetail) {
        let msg = { severity: 'error', summary: 'ERROR', detail: msgDetail};
        this.growl.show(msg);
    }

    /**
     * Metodo para mostrar mensaje de alerta cuando el usuario y/o password estan vacios
     * params:
     * return: se habilita mensaje de alerta
     **/
    showWarn() {
        let msg = { severity: 'warn', summary: 'AVISO', detail: 'You must specify username and password' };
        this.growl.show(msg);
    }

    /**
     * Metodo para mostrar mensaje de alerta generico
     * params:
     * return: se habilita mensaje de alerta
     **/
    showMsg(severityMsg, summaryMsg, detailMsg){
        let msg = { severity: severityMsg, summary: summaryMsg, detail: detailMsg };
        this.growl.show(msg);
    }



    render() {

        let header = (
            <div style={{'textAlign':'right'}}>
                <i className="pi pi-search" style={{margin:'4px 4px 0 0'}}></i>
                <InputText type="search" onInput={(e) => this.setState({globalFilter: e.target.value})} placeholder="Global Search" size="50"/>
            </div>
        );

        return(
            <div className="pageWrapper">
                <Growl ref={(el) => this.growl = el} />
                <Card>
                    <CardTitle>Causas</CardTitle>
                    <div className="p-grid">
                        <div className="p-col-9 ta-right" style={{position:"absolute", marginTop: -60}}>
                            {this.state.chargedYet ? '' :
                                <ProgressSpinner style={{width: '50px', height: '50px'}}  animationDuration=".5s"/>}
                        </div>
                    </div>
                    <Toolbar className="p-mb-4 mb-10">
                        <Dropdown value={this.state.merchantId} options={this.state.listMechants} onChange={(e) => {
                            this.setState({merchantId: e.value})
                        }} placeholder="Select Merchant"
                          className="mr-5"/>
                        <Button label='Search' onClick={this.callListPrincipal} />
                    </Toolbar>

                    <DataTable value={this.state.listPayments} selectionMode="multiple"
                               header={header} globalFilter={this.state.globalFilter} reorderableColumns={true}
                               paginator={true} rows={30}
                               resizableColumns={true} selection={this.state.dataTableSelectValue} onSelectionChange={(e) => this.setState({dataTableSelectValue: e.value})}>
                        <Column selectionMode="multiple" style={{width:'3em'}}/>
                        <Column field="product_desc.label" header="Product" sortable={true}/>
                        <Column field="amount" header="Amount" sortable={true} className="ta-right"/>
                        <Column field="fee" header="Fee" sortable={true} className="ta-right"/>
                        <Column field="status" header="Status" sortable={true} className="ta-right"/>
                        <Column field="dcreated" header="Created" sortable={true} className="ta-right"/>
                        <Column field="time" header="Time" sortable={true} className="ta-right"/>

                    </DataTable>
                </Card>
            </div>
        );
    }
}

export default Causas;
