import React, { Component } from 'react';
import Card from '../shared/Card';
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {Button} from "primereact/components/button/Button";
import CardTitle from "../shared/Card/Title";
import {HeisenbergService} from "../../service/heisenberg/HeisenbergService";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import { Growl } from 'primereact/growl';
import {InputText} from "primereact/components/inputtext/InputText";
import {Toolbar} from "primereact/toolbar";
import {ProgressSpinner} from "primereact/progressspinner";
import {Dialog} from "primereact/components/dialog/Dialog";

class AdminCausas extends Component {

    constructor() {
        super();
        this.apiHeisenberg = new HeisenbergService()
        this.state = {
            data: new FormData(),
            fileName: "",
            merchantId: "*",
            listMechants: [],
            listCausas: [],
            catBanks: [],
            bankId: "",
            globalFilter: null,
            chargedYet: false,
            newCausesDialog: false,
            deleteCausesDialog: false,
            isEditCauses: false,
            selectMerchantId: "*",
            labelCause: '',
            descriptionCause: '',
            dataTableSelectValue: null
        }

        this.callListPrincipal = this.callListPrincipal.bind(this);
        this.initCatalogos = this.initCatalogos.bind(this);
    }

    componentDidMount() {
        this.initCatalogos();
        this.callListPrincipal();
    }

    initCatalogos(){
        let lstMerchants = sessionStorage.getItem('lstMerchants');
        this.setState({
            listMechants: JSON.parse(lstMerchants)
        });
    }

    callListPrincipal = () => {
        const {
            merchantId
        } = this.state;
        this.apiHeisenberg = new HeisenbergService()
        this.apiHeisenberg.getReportDonationsCausas(merchantId).then(resp => {
            if(resp.status === 'success'){
                let listCausas = resp.data
                console.log('status '+resp.status)
                this.setState({listCausas,
                    chargedYet: true});
            }else{
            }
        }).catch(error => {
            this.showError(error.message);
            this.setState({chargedYet: true});
        });
    }

    dialogAction = action => () => {
        const {
            selectMerchantId,
            labelCause,
            descriptionCause,
            dataTableSelectValue,
            isEditCauses,
        } = this.state;
        this.apiHeisenberg = new HeisenbergService();
        switch (action) {
            case 'newCauses':
                if(selectMerchantId !== '*' && labelCause !== ''){
                    const params = {
                        'label': labelCause,
                        'description': descriptionCause
                    };
                    if (isEditCauses) {
                        this.apiHeisenberg.putCreateCausas(params, dataTableSelectValue.id).then(resp => {
                            this.callListPrincipal();
                            this.hideDialog();
                        }).catch(error => {
                            this.showError(error.message);
                            this.setState({chargedYet: true});
                        });
                    } else {
                        params.merchant = selectMerchantId;
                        this.apiHeisenberg.postCreateCausas(params).then(resp => {
                            this.callListPrincipal();
                            this.hideDialog();
                        }).catch(error => {
                            this.showError(error.message);
                            this.setState({chargedYet: true});
                        });
                    }
                } else {
                    this.showError('Merchant, Label and Amount are mandatory');
                }
                break;
            case 'deleteCauses':
                const params = {status: '0'};
                this.apiHeisenberg.putDeleteCausas(params, dataTableSelectValue.id).then(resp => {
                    this.callListPrincipal();
                    this.hideDialog();
                }).catch(error => {
                    this.showError(error.message);
                    this.setState({chargedYet: true});
                });
                break;
            default:
                break;
        }
    }

    showDialog = dialog => () => {
        const {
            dataTableSelectValue
        } = this.state;
        this.hideDialog();
        this.setState({
            selectMerchantId: '*',
            labelCause:'',
            descriptionCause: '',
            isEditCauses:false,
        });
        if(dialog === 'newCausesDialog'){
            this.setState({[dialog]: true});
        } else {
            if (dataTableSelectValue != null) {
                const showDialog = dialog=== 'editCausesDialog' ? 'newCausesDialog' : dialog;
                if(dialog === 'editCausesDialog'){
                    this.setState({isEditCauses: true});
                }
                this.setState({[showDialog]: true,
                    selectMerchantId: dataTableSelectValue.merchant,
                    labelCause: dataTableSelectValue.label,
                    descriptionCause: dataTableSelectValue.description});
            } else {
                const msg = { severity: 'error', summary: 'You need', detail: 'You need select a row'};
                this.growl.show(msg);
            }
        }
    }

    hideDialog = () => {
        this.setState({
            newCausesDialog: false,
            deleteCausesDialog: false
        });
    }

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    /**
     * Metodo para mostrar mensaje de error cuando el usuario y/o password son incorrectos
     * params:
     * return: se habilita mensaje de error
     **/
    showSucccess(msgDetail) {
        let msg = { severity: 'success', summary: 'OK', detail: msgDetail};
        this.growl.show(msg);
    }

    showError(msgDetail) {
        let msg = { severity: 'error', summary: 'ERROR', detail: msgDetail};
        this.growl.show(msg);
    }

    /**
     * Metodo para mostrar mensaje de alerta cuando el usuario y/o password estan vacios
     * params:
     * return: se habilita mensaje de alerta
     **/
    showWarn() {
        let msg = { severity: 'warn', summary: 'AVISO', detail: 'You must specify username and password' };
        this.growl.show(msg);
    }

    /**
     * Metodo para mostrar mensaje de alerta generico
     * params:
     * return: se habilita mensaje de alerta
     **/
    showMsg(severityMsg, summaryMsg, detailMsg){
        let msg = { severity: severityMsg, summary: summaryMsg, detail: detailMsg };
        this.growl.show(msg);
    }

    render() {
        const header = (
            <div style={{'textAlign':'right'}}>
                <i className="pi pi-search" style={{margin:'4px 4px 0 0'}}></i>
                <InputText type="search" onInput={(e) => this.setState({globalFilter: e.target.value})} placeholder="Global Search" size="50"/>
            </div>
        );
        const footerDialog = action => (
            <React.Fragment>
                <Button label="Cancel" icon={action === 'deleteCauses' ? 'pi pi-check' : 'pi pi-times'}
                        className="p-button-text" onClick={this.hideDialog} />
                <Button label={action === 'newCauses' ? (!this.state.isEditCauses ?  'New' : 'Update') : 'Delete'}
                        icon={action === 'deleteCauses' ? 'pi pi-times' : 'pi pi-check'}
                        className={action === 'deleteCauses' ? 'p-button-danger' : ''}
                        onClick={this.dialogAction(action)} />
            </React.Fragment>
        );

        const dialogs = (
            <div>
                <Dialog visible={this.state.newCausesDialog} style={{width: '450px', height: '300px'}}
                        header="New Cause" modal className="p-fluid" footer={footerDialog('newCauses')}
                        onHide={this.hideDialog}>
                    <div className="p-field" style={{height: '200px'}}>
                        <div className="p-grid">
                            <div className="p-col-12 p-md-6">
                                <label className="p-d-block">Select a merchant</label>
                                <Dropdown value={this.state.selectMerchantId} options={this.state.listMechants}
                                          onChange={(e) => {
                                              this.setState({selectMerchantId: e.value})
                                          }}
                                          disabled={this.state.isEditCauses ? true : false}
                                />
                            </div>
                            <div className="p-col-12 p-md-6">
                                <label htmlFor="dialogNewLabel" className="p-d-block">Label</label>
                                <InputText
                                    placeholder="Label"
                                    name="labelCause"
                                    onChange={this.changeHandler}
                                    value={this.state.labelCause}
                                    id="dialogNewLabel"
                                />
                            </div>
                            <div className="p-col-12 p-md-6">
                                <label htmlFor="dialogNewDescription" className="p-d-block">Description</label>
                                <InputText
                                    placeholder="Description"
                                    name="descriptionCause"
                                    onChange={this.changeHandler}
                                    value={this.state.descriptionCause}
                                    id="dialogNewDescription"
                                />
                            </div>
                        </div>
                    </div>
                </Dialog>
                <Dialog visible={this.state.deleteCausesDialog} style={{width: '450px', height: '200px'}}
                        header="Delete Cause" modal className="p-fluid" footer={footerDialog('deleteCauses')}
                        onHide={this.hideDialog}>
                    <div className="p-field">
                        {this.state.dataTableSelectValue != null ? <p> <b> Merchant: </b> {this.state.dataTableSelectValue.merchant} </p> : ''}
                        {this.state.dataTableSelectValue != null ? <p> <b> Label: </b> {this.state.dataTableSelectValue.label} </p> : ''}
                    </div>
                </Dialog>
            </div>
        );

        return(
            <div className="pageWrapper">
                <Growl ref={(el) => this.growl = el} />
                <Card>
                    <CardTitle>Admin Causes</CardTitle>
                    <div className="p-grid">
                        <div className="p-col-9 ta-right" style={{position:"absolute", marginTop: -60}}>
                            {this.state.chargedYet ? '' :
                                <ProgressSpinner style={{width: '50px', height: '50px'}} animationDuration=".5s"/>}
                        </div>
                    </div>
                    <Toolbar className="p-mb-4 mb-10">
                        <Dropdown value={this.state.merchantId} options={this.state.listMechants} onChange={(e) => {
                            this.setState({merchantId: e.value})
                        }} placeholder="Select Merchant"
                          className="mr-5 height-100"/>
                        <Button label='Search' onClick={this.callListPrincipal} />
                        <Button label='New' onClick={this.showDialog('newCausesDialog')} />
                        <Button label='Edit' onClick={this.showDialog('editCausesDialog')} />
                        <Button label='Delete' className="p-button-danger ta-right" onClick={this.showDialog('deleteCausesDialog')} />
                    </Toolbar>

                    <DataTable value={this.state.listCausas} selectionMode="single"
                               header={header} globalFilter={this.state.globalFilter} reorderableColumns={true}
                               paginator={true} rows={30}
                               resizableColumns={true} selection={this.state.dataTableSelectValue} onSelectionChange={(e) => this.setState({dataTableSelectValue: e.value})}>
                        <Column selectionMode="single" style={{width:'3em'}}/>
                        <Column field="merchant" header="Merchant" sortable={true} style={{width:'20em'}}/>
                        <Column field="label" header="Label" sortable={true} className="ta-right"/>
                        <Column field="status" header="Status" sortable={true} className="ta-right"/>
                        <Column field="dcreated" header="Created" sortable={true} className="ta-right"/>
                    </DataTable>
                    {dialogs}
                </Card>
            </div>
        );
    }
}

export default AdminCausas;
