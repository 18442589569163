import React, { Component } from 'react';
import Card from '../shared/Card';
import {HeisenbergService} from "../../service/heisenberg/HeisenbergService";
import {CarService} from "../../service/CarService";
import {UsuarioService} from "../../service/login/UsuarioService";
import {Growl} from "primereact/growl";
import {ProgressSpinner} from "primereact/progressspinner";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {InputText} from "primereact/components/inputtext/InputText";


class DashboardAlert extends Component {

    constructor() {
        super();
        this.apiHeisenberg = new HeisenbergService()
        this.usuarioService = new UsuarioService()
        this.state = {
            globalFilter: null,
            dialogVisible: false,
            expandedRows: {},
            selectedRowData: null,
            data: new FormData(),
            dataTableSelectValue: new FormData(),
            dataTableSelectValueAlert: new FormData(),
            fileName: "",
            merchantId: "",
            listRecipient: [],
            listAlerts: [],
            dataTableValue: [],
            monthlyDataset: {},
            merchantData: {},
            mapCountryData: {},
            arratCountryData: [],
            lastDonations: [],
            chargedYet: true,
            newDialog: false,
            newAlertDialog: false,
            deleteDialog: false,
            isEditCauses: false,
            isEditAlert: false,
            reportTypeData: {},
            alert_data: {},
            test_phone: '',
            test_name: '',
            master_min:'',
            master_txt:'',
            master_name:'',
        }
        this.countries = {
            "AG": "Antigua and Barbuda",
            "AI": "Anguilla",
            "BS": "The Bahamas",
            "BB": "Barbados",
            "BZ": "Belize",
            "BR": "Brazil",
            "CA": "Canada",
            "CO": "Colombia",
            "CR": "Costa Rica",
            "CU": "Cuba",
            "DM": "Dominica",
            "DO": "Dominican Republic",
            "FR": "France",
            "GH": "Ghana",
            "GD": "Grenada",
            "GY": "Guyana",
            "HT": "Haiti",
            "HN": "Honduras",
            "JM": "Jamaica",
            "NL": "Netherlands",
            "MX": "Mexico",
            "NI": "Nicaragua",
            "PA": "Panama",
            "KN": "Saint Kitts and Nevis",
            "LC": "Saint Lucia",
            "VC": "Saint Vincent and the Grenadines",
            "TT": "Trinidad and Tobago",
            "GB": "United Kingdom",
            "US": "United States",
            "VE": "Venezuela",
            "DE": "Germany",
            "TR": "Turkey",
            "SR":"Suriname",
            "BD":"Bangladesh"
        }

        this.carService = new CarService();

        this.getMaster = this.getMaster.bind(this);
    }

    componentDidMount() {
        this.getMaster();
    }




    showSucccess(msgDetail) {
        let msg = { severity: 'success', summary: 'OK', detail: msgDetail, life: 7000};
        this.growl.show(msg);
    }




    getMaster(){
        this.setState({chargedYet: false});
        this.usuarioService = new UsuarioService()
        this.usuarioService.postListAgentsFee().then(resp => {
            const lst_kycs = resp.data
            this.setState({lst_kycs,
                chargedYet: true});
        }).catch(error => {
        });
    }

    countryBodyTemplate = (rowData, column) => {
        return this.countries[rowData.country];
    }


    feeBodyTemplate = (rowData, column) => {
        const feeFormatted = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(rowData.extern_fee);
        return feeFormatted;
    }






    render() {
        let header = (
            <div style={{'textAlign':'right'}}>
                <i className="pi pi-search" style={{margin:'4px 4px 0 0'}}></i>
                <InputText type="search" onInput={(e) => this.setState({globalFilter: e.target.value})} placeholder="Global Search" size="50"/>
            </div>
        );

        return(
            <div className="pageWrapper">
                <Growl ref={(el) => this.growl = el}/>
                <div className="p-grid">
                    <div className="p-grid">
                        <div className="p-col-9 ta-right" style={{position: "absolute", marginTop: -60}}>
                            {this.state.chargedYet ? '' :
                                <ProgressSpinner style={{width: '50px', height: '50px'}}
                                                 animationDuration=".5s"/>}
                        </div>
                    </div>
                    <Card height="height-100">
                        <div className="p-col-12">
                            <Card>
                                <DataTable value={this.state.lst_kycs}
                                           responsiveLayout="scroll"
                                           globalFilter={this.state.globalFilter}
                                           header={header}
                                           style={{ width: '100%' }} >
                                    <Column field="user" header="Name" className={'ta-center'}/>
                                    <Column field="country" header="Country" body={this.countryBodyTemplate} className={'ta-center'}/>
                                    <Column field="country" header="Country Code" className={'ta-center'}/>
                                    <Column field="extern_fee" header="Fee" body={this.feeBodyTemplate} className={'ta-center'} />
                                    <Column field="d_created" header="Created" className={'ta-center'}/>
                                </DataTable>
                            </Card>
                        </div>
                    </Card>
                </div>
            </div>
        );
    }
}

export default DashboardAlert;
