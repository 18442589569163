import React, { Component } from 'react';
import Card from '../shared/Card';
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {Button} from "primereact/components/button/Button";
import CardTitle from "../shared/Card/Title";
import {HeisenbergService} from "../../service/heisenberg/HeisenbergService";
import { Growl } from 'primereact/growl';
import {InputText} from "primereact/components/inputtext/InputText";
import {Dialog} from "primereact/components/dialog/Dialog";
import {Toolbar} from "primereact/toolbar";
import {ProgressSpinner} from "primereact/progressspinner";
import {UsuarioService} from "../../service/login/UsuarioService";
import {STATUS_TOKEN_CHOICES} from "../utils/constants";

class TransactionsWallet extends Component {

    constructor(){
        super();
        this.apiHeisenberg = new HeisenbergService()
        this.state ={
            data : new FormData(),
            fileName : "",
            merchantId : "*",
            listMechants : [],
            listTrans: [],
            catBanks:[],
            bankId:"",
            globalFilter: null,
            dataTableSelectValue: {payment_desc:{}},
            chargedYet: true,
            detailDialog: false,
            balance_tpp: 0.0,
            previous:'',
            next:'',
            cardToken:'',
            totalRows:0,
            page:0,
            pageSize:0,

            status_token_ewi:'Without card'
        }

        this.callListPrincipal = this.callListPrincipal.bind(this);
        this.initCatalogos = this.initCatalogos.bind(this);
        this.actionBodyDetail = this.actionBodyDetail.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.getBalanceCardToken = this.getBalanceCardToken.bind(this);
        this.callListPage = this.callListPage.bind(this);
        this.callListPageNext = this.callListPageNext.bind(this);
        this.callListPagePrevious = this.callListPagePrevious.bind(this);
        this.callListByToken = this.callListByToken.bind(this);
        this.allTransactionsDialog = this.allTransactionsDialog.bind(this);
        this.sendCardBalance = this.sendCardBalance.bind(this);
        this.blockCard = this.blockCard.bind(this);
        this.stolenCard = this.stolenCard.bind(this);
        this.sendCardBalance = this.sendCardBalance.bind(this);
        this.getStatusCardDetail = this.getStatusCardDetail.bind(this);
    }

    componentDidMount() {

        this.initCatalogos();
        this.callListPrincipal();
    }

    initCatalogos(){
        this.apiHeisenberg = new HeisenbergService()
        let lstMerchants = sessionStorage.getItem('lstMerchants');
        this.setState({
            listMechants: JSON.parse(lstMerchants)
        });
    }

    callListPrincipal(){
        this.usuarioService = new UsuarioService()
        this.setState({
            chargedYet: false});
        this.usuarioService.postReportWalletTransactions().then(resp => {
            const listTrans = resp.data;
            //const previous = resp.links.previous;
            //const next = resp.links.next;
            //const totalRows= resp.total;
            //const page= resp.page;
            //const pageSize= resp.page_size;
            //#const page:'',
            this.setState({
                listTrans,
                chargedYet: true});
        }).catch(error => {
            this.setState({
                chargedYet: true});
            //swal("ERROR", error.response.data.messageResponse, "error");
        });
    }

    callListByToken(){
        this.usuarioService = new UsuarioService()

        this.usuarioService.getReportWebHookTransByToken(this.state.cardToken).then(resp => {
            //const listTrans = resp.data;
            const previous = '';
            const next = '';
            this.setState({
                previous,next,
                chargedYet: true});


        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });
    }

    callListPageNext(){
        this.callListPage(this.state.next)
    }

    callListPagePrevious(){
        this.callListPage(this.state.previous)
    }

    callListPage(link){
        console.log('callListPage')
        if (link.length>0 && ! link.includes('localhost')) {
            link = link.replace('http','https')
        }
        console.log(link)

        this.usuarioService = new UsuarioService()

        this.usuarioService.getReportWebHookTransPage(link).then(resp => {
            //const listTrans = resp.results;
            const previous = resp.links.previous;
            const next = resp.links.next;
            const totalRows= resp.total;
            const page= resp.page;
            const pageSize= resp.page_size;
            this.setState({
                previous,next,totalRows,page,pageSize,
                chargedYet: true});




        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });
    }

    actionBodyDetail() {
        //this.getBalanceCardToken();
        //this.getStatusCardDetail();
        this.setState({
            code:"",
            cardToken:"",
            operation:"payment",
            detailDialog: true,
            balance_tpp:0.0
        });
    }

    getBalanceCardToken(){
        this.setState({chargedYet: false})
        this.usuarioService = new UsuarioService()
        let params = {token :this.state.dataTableSelectValue.cardToken}
        this.usuarioService.postBalanceCardTokenTpp(params).then(resp => {
            if(resp.status === 'success'){
                const balance = resp.data.Balance / 100;
                const balance_tpp = balance.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                this.setState({
                    balance_tpp,
                    chargedYet: true});
                //this.showSucccess(resp.time_react)
            }else{
                //swal("ERROR", resp.messageResponse, "error");
            }
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });
    }

    getStatusCardDetail(){
        const status_token_ewi = 'Without card';
        this.usuarioService = new UsuarioService();
        let params = {card_token: this.state.dataTableSelectValue.cardToken}
        this.usuarioService.postTokenStatusCardDetail(params).then(resp => {
            if(resp.code === 200){
                const data = resp.data;
                const status_token_ewi = data.status_token_ewi === 1 ? 'BLOCK_CARD' : data.status_token_ewi === 2 ? 'STOLEN_CARD' : 'ACTIVE';
                this.setState({status_token_ewi});
            }else if(resp.code === 204){
                this.setState({status_token_ewi});
            }else {
                this.setState({status_token_ewi});
            }
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });
    }

    /**
     * Metodo para mostrar mensaje de error cuando el usuario y/o password son incorrectos
     * params:
     * return: se habilita mensaje de error
     **/
    showSucccess(msgDetail) {
        let msg = { severity: 'success', summary: 'OK', detail: msgDetail};
        this.growl.show(msg);
    }

    showError(msgDetail) {
        let msg = { severity: 'error', summary: 'ERROR', detail: msgDetail};
        this.growl.show(msg);
    }

    /**
     * Metodo para mostrar mensaje de alerta cuando el usuario y/o password estan vacios
     * params:
     * return: se habilita mensaje de alerta
     **/
    showWarn() {
        let msg = { severity: 'warn', summary: 'AVISO', detail: 'You must specify username and password' };
        this.growl.show(msg);
    }

    /**
     * Metodo para mostrar mensaje de alerta generico
     * params:
     * return: se habilita mensaje de alerta
     **/
    showMsg(severityMsg, summaryMsg, detailMsg){
        let msg = { severity: severityMsg, summary: summaryMsg, detail: detailMsg };
        this.growl.show(msg);
    }

    hideDialog() {
        this.setState({
            detailDialog: false,
        });
    }

    allTransactionsDialog(){
        let cardToken = this.state.dataTableSelectValue.cardToken;
        this.setState({cardToken,
            detailDialog: false,
        });
        this.usuarioService = new UsuarioService()
        this.usuarioService.getReportWebHookTransByToken(cardToken).then(resp => {
            //const listTrans = resp.data;
            const previous = '';
            const next = '';
            this.setState({
                previous,next,
                chargedYet: true});


        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });

    }

    sendCardBalance() {
        this.props.transactionSelected(this.state.dataTableSelectValue);
    }

    showRefundDialog = () => {
        const {
            dataTableSelectValue
        } = this.state;
        if (dataTableSelectValue.id === undefined) {
            const msg = { severity: 'error', summary: 'You need', detail: 'Select a row to refund'};
            this.growl.show(msg);
        } else {
            this.setState({refundDialog: true});
        }
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    blockCard(){
        let params = {
            card_token: this.state.dataTableSelectValue.cardToken,
            status: STATUS_TOKEN_CHOICES.BLOCK_CARD
        }
        console.log("Block Card params: ", params);
        this.usuarioService = new UsuarioService()
        this.usuarioService.postTokenStatusCard(params).then(resp => {
            if (resp.code === 200) {
                this.setState({detailDialog: false});
                this.showSucccess("Block Card success!");
            } else if (resp.code === 204) {
                this.showError(`${resp.message}!`);
            } else {
                this.showError("Service error!");
            }
        }).catch(error => {
            console.error("postTokenStatusCard: ",error);
        });

    }
    stolenCard(){
        let params = {
            card_token: this.state.dataTableSelectValue.cardToken,
            status: STATUS_TOKEN_CHOICES.STOLEN_CARD
        }
        console.log("Stolen Card params: ", params);
        this.usuarioService = new UsuarioService();
        this.usuarioService.postTokenStatusCard(params).then(resp => {
            if (resp.code === 200) {
                this.setState({detailDialog: false});
                this.showSucccess("Block Card success!");
            } else if (resp.code === 204) {
                this.showError(`${resp.message}!`);
            } else {
                this.showError("Service error!");
            }
        }).catch(error => {
            console.error("postTokenStatusCard: ",error);
        });
    }

    render() {


       /* const dialogFooterCancel = <div className="ui-dialog-buttonpane p-clearfix">
            <Button label="Submit" icon="pi pi-check" onClick={this.callAction}/>
        </div>;*/

        




        /*const bodyTransTemplateAmt = (rowData,column) => {
            const stockClassName = classNames({
                'lowstock': rowData[column.field] <= 0,
                'instock': rowData[column.field] > 0
            });

            return (
                <div className={stockClassName}>
                    {rowData[column.field]}
                </div>
            );
        }*/
        const formatCurrency = (field) => {
            return (rowData) => parseFloat(rowData[field]).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        }
        /*const dateTemplate = (rowData) => {
            let [date] = rowData.created.split(" ");
            return <span>{date}</span>;
        };*/

        let header = (
            <div style={{'textAlign':'right'}}>
                <i className="pi pi-search" style={{margin:'4px 4px 0 0'}}></i>
                <InputText type="search" onInput={(e) => this.setState({globalFilter: e.target.value})} placeholder="Global Search" size="50"/>
            </div>
        );

        return(
            <div className="pageWrapper">
                <Growl ref={(el) => this.growl = el} />
                <Card>
                    <CardTitle>Transactions Wallet</CardTitle>
                    <div className="p-grid">
                        <div className="p-col-9 ta-right" style={{position:"absolute", marginTop: -60}}>
                            {this.state.chargedYet ? '' :
                                <ProgressSpinner style={{width: '50px', height: '50px'}}  animationDuration=".5s"/>}
                        </div>
                    </div>

                    {/*<Toolbar className="p-mb-4 mb-10">
                        <label style={{padding:'10px'}} htmlFor="cardToken">Card Token</label>
                        <InputText id="cardToken" name="cardToken" value={cardToken} onChange={this.handleChange}/>
                        <Button style={{marginLeft:'10px'}} label="Search" onClick={this.callListByToken} />
                    </Toolbar>*/}

                    <Toolbar className="p-mb-4 mb-10">
                        {/*<Button label="Reset" onClick={this.callListPrincipal} />*/}
                        <Button label="Detail" onClick={this.actionBodyDetail}/>
                        <Button label="<<" onClick={this.callListPagePrevious} />
                        <label htmlFor="page">Page {this.state.page}  </label>
                        <Button label=">>" onClick={this.callListPageNext}/>
                        <label htmlFor="total">Total {this.state.totalRows}  </label>
                    </Toolbar>

                    <div style={{width:'100%', height:'500px', overflow: 'scroll'}}>
                        <DataTable     value={this.state.listTrans}
                                       scrollable
                                       style={{width: '20   00px'}}
                                       resizableColumns={true}
                                       reorderableColumns={true}
                                       header={header}
                                       globalFilter={this.state.globalFilter}
                                       selectionMode="single"
                                       paginator={true}
                                       rows={1500}
                                       selection={this.state.dataTableSelectValue}
                                       onSelectionChange={(e) => this.setState({dataTableSelectValue: e.value})}>
                            <Column selectionMode="single" style={{width:'5%'}}/>
                            <Column field="username" header="User Name" sortable={true} className="ta-center" style={{width:'17%'}}/>
                            <Column field="sender" header="Sender" sortable={true} className="ta-center" style={{width:'17%'}}/>
                            <Column field="receiver" header="Receiver" sortable={true} className="ta-center" style={{width:'15%'}}/>
                            <Column field="type_transfer" header="Type Transfer" sortable={true} className="ta-center" style={{width:'15%'}}/>
                            <Column field="status_transfer" header="Status" sortable={true} className="ta-center" style={{width:'15%'}}/>
                            <Column field="type_sender" header="Type Sender" sortable={true} className="ta-center" style={{width:'15%'}}/>
                            <Column field="type_receiver" header="Type Receiver" sortable={true} className="ta-center" style={{width:'15%'}}/>
                            <Column field="amount" header="Amount" body={formatCurrency("amount")} sortable={true} className="ta-center" style={{width:'15%'}}/>
                            <Column field="currency" header="Currency" sortable={true} className="ta-center" style={{width:'15%'}}/>
                        </DataTable>

                    </div>




                    <Toolbar className="p-mb-4 mb-10">
                        <Button label="Reset" onClick={this.callListPrincipal} />
                        <Button label="Detail" onClick={this.actionBodyDetail}/>
                        <Button label="<<" onClick={this.callListPagePrevious} />
                        <label htmlFor="page">Page {this.state.page}  </label>
                        <Button label=">>" onClick={this.callListPageNext}/>
                        <label htmlFor="total">Total {this.state.totalRows}  </label>
                    </Toolbar>




                    <Dialog visible={this.state.detailDialog} style={{height: '270px'}}
                            header="Detail Transaction" modal className="p-fluid"
                            onHide={this.hideDialog}>
                        <div className="datail-transaction">
                                <p>Username: </p> <span>{this.state.dataTableSelectValue.username}</span>
                                <p>Sender: </p> <span>{this.state.dataTableSelectValue.sender}</span>
                                <p>Receiver: </p> <span>{this.state.dataTableSelectValue.receiver}</span>
                                <br/>
                                <p>Amount: </p> <span>{parseFloat(this.state.dataTableSelectValue.amount).toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD'
                                    })}</span>
                                <p>Type Transfer: </p>
                                <span>{this.state.dataTableSelectValue.type_transfer}</span>
                                <p>Status Transfer: </p> <span>{this.state.dataTableSelectValue.status_transfer}</span>
                                <br/>
                                <p>Type Sender: </p> <span>{this.state.dataTableSelectValue.type_sender}</span>
                                <p>Type Receiver: </p>
                                <span>{this.state.dataTableSelectValue.type_receiver}</span>
                                <p>Currency: </p> <span>{this.state.dataTableSelectValue.currency}</span>
                                <br/>
                                {/*<p>Response Reason:              </p> <span>{this.state.dataTableSelectValue.response_reason}</span>*/}
                                <hr/>
                            </div>
                    </Dialog>


                </Card>
            </div>
        );
    }
}

export default TransactionsWallet;
