import React, { Component } from 'react';
import SubMenu from '../shared/SubMenu';
import SubMenuItem from '../shared/SubMenu/Item';
import Apis from "./apis";
import ReportTypeTransfer from "./reportTypeTransfer";
//import ReportGlobalTypeTransfer from "./reportGlobalTypeTransfer";

class ReportApisHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            opcionMenuSelected:'Apis',
        }

        this.actionToApis = this.actionToApis.bind(this);
        this.actionToTypeTransfer = this.actionToTypeTransfer.bind(this);

    }

    actionToApis (event) {
        this.setState({opcionMenuSelected: 'Apis'});
    }

    actionToTypeTransfer (event) {
        this.setState({opcionMenuSelected: 'TypeTransfer'});
    }


    render() {
        return (
            <div className="pageWrapper">
                {/* Sub Menu */}
                <SubMenu>
                    <SubMenuItem id="menu1" className={this.state.opcionMenuSelected === 'Apis' ? "active" : ''}  onClick={this.actionToApis}>
                        Payments
                    </SubMenuItem>

                    <SubMenuItem id="menu1" className={this.state.opcionMenuSelected === 'TypeTransfer' ? "active" : ''}  onClick={this.actionToTypeTransfer}>
                        Report Type Transfer
                    </SubMenuItem>


                </SubMenu>

                {/* Page Content */}
                {(this.state.opcionMenuSelected==='Apis') && <Apis />}
                {(this.state.opcionMenuSelected==='TypeTransfer') && <ReportTypeTransfer />}

            </div>
        );
    }
}

export default ReportApisHome;
