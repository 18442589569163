import React, {Component} from 'react';
import SubMenu from "../shared/SubMenu";
import SubMenuItem from "../shared/SubMenu/Item";
//import ReportLevel0 from "./reportLevel0";
//import ReportLevel1 from "./reportLevel1";
import ReportLevel2 from "./reportLevel2";

class FeeReportLevel2 extends Component {
    constructor() {
        super();
        this.state = {
            opcionMenuSelected: 'level2',
        };
        this.changeHandler = this.changeHandler.bind(this);
    }

    changeHandler = event => {
        this.setState({opcionMenuSelected: event.target.name});
    };

    render() {
        return (
            <div className="pageWrapper">
                {/* Sub Menu */}
                <SubMenu>

                    <SubMenuItem id="menu2"
                                 className={this.state.opcionMenuSelected === 'level2' ? "active" : ''} name="level2"
                                 onClick={this.changeHandler}>
                        Report Master Reseller
                    </SubMenuItem>
                </SubMenu>

                {/* Page Content */}
                {(this.state.opcionMenuSelected === 'level2') && <ReportLevel2/>}

            </div>
        );
    }
}

export default FeeReportLevel2;