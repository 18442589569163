import React, {Component} from "react";
import {Button} from "primereact/components/button/Button";
import {InputText} from "primereact/components/inputtext/InputText";
import {InputTextarea} from "primereact/components/inputtextarea/InputTextarea";
import swal from 'sweetalert';
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import CardTitle from "../shared/Card/Title";
import Card from "../shared/Card";
import {UsuarioService} from "../../service/login/UsuarioService";
import {isAmount, isValueText, validateEmail, validatePhone, valueIsNumber} from "../utils/commonFunctions";
import {Growl} from "primereact/growl";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';


class RequestPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            merchant_key: '',
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            paymentAmount: '',
            invoiceNumber: '',
            comments: '',
            invoiceFile: '',
            type: '1',
            merchants:[],
            requiredPresent: {
                merchant_key: true,
                firstName: true,
                lastName: true,
                email: true,
                phone: true,
                paymentAmount: true,
                invoiceNumber: true,
                comments: true,
            },
        }
    }

    componentDidMount() {

        let lstMerchants = sessionStorage.getItem('lstMerchants');

        this.setState({
            merchants: JSON.parse(lstMerchants)
        });
    }

    handleChangeText = e => {
        if(isValueText(e.target.value)){
            this.setState({
                [e.target.name]: e.target.value,
            });
        }
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    handleChangeAmount = e => {
        if (isAmount(e.target.value)) {
            this.setState({
                [e.target.name]: e.target.value,
            });
        }
    };

    handleEmail  = e => {
        if(!validateEmail(e.target.value)){
            const msg = { severity: 'error', summary: 'email', detail: 'Format not valid'};
            this.growl.show(msg);
        }
    };
    handleChangeNumber = e => {
        if (valueIsNumber(e.target.value)) {
            this.setState({
                [e.target.name]: e.target.value,
            });
        }
    };

    submitHandler = e => {
        const {
            merchant_key,
            firstName,
            lastName,
            email,
            phone,
            paymentAmount,
            invoiceNumber,
            comments,
        } = this.state;
        let isValid = this.validateSubmit();
        if(!isValid){
            swal({
                title: "Error",
                text: "All fields are mandatory!",
                icon: "error",
            });
        }
        if (isValid) {
            swal({
                title: "Are you sure?",
                text: "Are you, send invoice payment!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willSend) => {
                if (willSend) {
                    this.usuarioService = new UsuarioService();
                    this.usuarioService.postCreateInvoiceBtc({
                        merchant_key: merchant_key,
                        first_name: firstName,
                        last_name: lastName,
                        email: email,
                        phone: phone,
                        amount: paymentAmount,
                        invoice_number: invoiceNumber,
                        comments: comments,
                    }).then(resp => {
                        if (resp.status === 'success') {
                            this.setState({
                                merchant_key: '',
                                firstName: '',
                                lastName: '',
                                email: '',
                                phone: '',
                                paymentAmount: '',
                                invoiceNumber: '',
                                comments: '',
                                invoiceFile: '',
                            });
                            swal({
                                title: "Success",
                                text: "Your invoice was sent!",
                                icon: "success",
                            });
                        } else {
                            swal({title: "ERROR", text: resp["messageResponse"], icon: "error"});
                        }
                    }).catch(error => {
                        swal({title: "ERROR", text: error.response.data.messageResponse, icon: "error"});
                    });
                }
            });
        }
    }

    validateSubmit = () => {
        const {
            firstName,
            lastName,
            email,
            phone,
            paymentAmount,
            invoiceNumber,
            comments,
        } = this.state;
        const requiredPresent= {
            firstName: true,
            lastName: true,
            email: true,
            phone: true,
            paymentAmount: true,
            invoiceNumber: true,
            comments: true,
        };

        if(firstName.trim() === '')
            requiredPresent.firstName = false;
        if(lastName.trim() === '')
            requiredPresent.lastName = false;
        if(!validateEmail(email))
            requiredPresent.email = false;
        if(!validatePhone(phone))
            requiredPresent.phone = false;
        if(paymentAmount.trim() === '')
            requiredPresent.paymentAmount = false;
        if(invoiceNumber.trim() === '')
            requiredPresent.invoiceNumber = false;
        if(comments.trim() === '')
            requiredPresent.comments = false;

        this.setState({requiredPresent});
        if(!requiredPresent.firstName ||
            !requiredPresent.lastName ||
            !requiredPresent.email ||
            !requiredPresent.phone ||
            !requiredPresent.paymentAmount ||
            !requiredPresent.invoiceNumber ||
            !requiredPresent.comments){
            return false;
        }
        return true;
    }
    render() {
        const {
            merchant_key,
            firstName,
            lastName,
            email,
            phone,
            paymentAmount,
            invoiceNumber,
            comments,
            merchants,
            requiredPresent,
        } = this.state;

        const requestBody = (
            <div className="p-grid p-fluid">
                <div className="card card-w-title">
                    <h1>InputText</h1>
                    <div className="p-grid">
                        <div className="p-col-12 p-md-4">
                            <label className={!requiredPresent.firstName ? 'l-error' : ''}>First Name:*</label>
                            <InputText value={firstName} name='firstName' onChange={this.handleChangeText}
                                       className={!requiredPresent.firstName ? 'p-error' : ''}
                            />
                        </div>
                        <div className="p-col-12 p-md-4">
                            <label className={!requiredPresent.lastName ? 'l-error' : ''}>Last Name:*</label>
                            <InputText value={lastName} name='lastName' onChange={this.handleChangeText}
                                       className={!requiredPresent.lastName ? 'p-error' : ''}
                            />
                        </div>
                        <div className="p-col-12 p-md-4">
                            <label className={!requiredPresent.email ? 'l-error' : ''}>Email:*</label>
                            <InputText value={email} name='email' placeholder="email@example.com"
                                       onChange={this.handleChange} onBlur={this.handleEmail}
                                       className={!requiredPresent.email ? 'p-error' : ''}
                            />
                        </div>
                        <div className="p-col-12 p-md-4">
                            <label className={!requiredPresent.phone ? 'l-error' : ''}>Phone:*</label>
                            <PhoneInput
                                value={phone}
                                name="Phone"
                                enableSearch={true}
                                className="inputStyleProfile"
                                style={{with:'100%'}}
                                onChange={phone => this.setState({ phone: phone })}
                                //className={!requiredPresent.phone ? 'p-error' : ''}
                            />
                        </div>
                        <div className="p-col-12 p-md-4">
                            <label className={!requiredPresent.paymentAmount ? 'l-error' : ''}>Payment Amount USD:*</label>
                            <InputText value={paymentAmount} name='paymentAmount' onChange={this.handleChangeAmount}
                                       className={!requiredPresent.paymentAmount ? 'p-error' : ''}
                            />
                        </div>
                        <div className="p-col-12 p-md-4">
                            <label className={!requiredPresent.invoiceNumber ? 'l-error' : ''}>Invoice Number:*</label>
                            <InputText value={invoiceNumber} name='invoiceNumber' onChange={this.handleChange}
                                       className={!requiredPresent.invoiceNumber ? 'p-error' : ''}
                            />
                        </div>
                        <div className="p-col-12 p-md-8">
                            <label className={!requiredPresent.comments ? 'l-error' : ''}>Comments:*</label>
                            <InputTextarea rows={6} cols={40} value={comments} name='comments' onChange={this.handleChange}
                                           className={!requiredPresent.comments ? 'p-error' : ''}
                            />
                        </div>
                        <div className="p-col-12 p-offset-4 p-md-4">
                            <Button label="Submit" onClick={this.submitHandler}/>
                        </div>
                    </div>
                </div>
            </div>
        );
        return (
            <Card>
                <Growl ref={(el) => this.growl = el} />
                <CardTitle>Request Payment</CardTitle>
                <div className="p-grid">
                    <label>Enter details below and we will send email with simple instructions on how to
                        pay.</label>
                </div>
                <br/>
                <div className="p-grid">
                    <div className="p-col-2">
                        <label>Select Merchant:* </label>
                    </div>
                    <div className="p-col-4">
                        <Dropdown value={merchant_key} options={merchants} style={{width: '100%'}}
                                  onChange={(e) => {
                                      this.setState({merchant_key: e.value})
                                  }}
                                  placeholder="Select a Merchant"/>
                    </div>
                </div>
                {(merchant_key !== '') && requestBody}
            </Card>
        );
    }
}

export default RequestPayment;
