import React, {Component} from 'react';
import SubMenu from "../shared/SubMenu";
import SubMenuItem from "../shared/SubMenu/Item";
//import ReportLevel0 from "./reportLevel0";
import ReportLevel1 from "./reportLevel1";
//import ReportLevel2 from "./reportLevel2";
//import CardTitle from "../shared/Card/Title";

class FeeReportLevel1 extends Component {
    constructor() {
        super();
        this.state = {
            opcionMenuSelected: 'level1',
        };
        this.changeHandler = this.changeHandler.bind(this);
    }

    changeHandler = event => {
        this.setState({opcionMenuSelected: event.target.name});
    };

    render() {
        return (
            <div className="pageWrapper">
                {/* Sub Menu */}
                <SubMenu>
                    <SubMenuItem id="menu1"
                                 className={this.state.opcionMenuSelected === 'level1' ? "active" : ''} name="level1"
                                 onClick={this.changeHandler}>
                        Report Processor
                    </SubMenuItem>
                </SubMenu>

                {/* Page Content */}
                {(this.state.opcionMenuSelected === 'level1') && <ReportLevel1/>}

            </div>
        );
    }
}

export default FeeReportLevel1;