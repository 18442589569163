import React, { Component } from 'react';
import Card from '../shared/Card';
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {Button} from "primereact/components/button/Button";
import CardTitle from "../shared/Card/Title";
import {HeisenbergService} from "../../service/heisenberg/HeisenbergService";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {Growl} from 'primereact/growl';
import {InputText} from "primereact/components/inputtext/InputText";
import {UsuarioService} from "../../service/login/UsuarioService";
import {Toolbar} from "primereact/toolbar";
import {Dialog} from "primereact/dialog";

class ReportPayment extends Component {

    constructor(){
        super();
        this.apiHeisenberg = new HeisenbergService()
        this.state = {
            data: new FormData(),
            fileName: "",
            merchantId: "*",
            listMechants: [],
            listPayments: [],
            listAddressAuthorize: [],
            productDialog: false,
            productDialogConfirm: false,
            catBanks: [],
            bankId: "",
            globalFilter: null,
            address_id: 0,
            amount: ''
        }

        this.callListPrincipal = this.callListPrincipal.bind(this);
        this.loadMerchants = this.loadMerchants.bind(this);
        this.openNew = this.openNew.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.loadAddressAuthorize = this.loadAddressAuthorize.bind(this);

        this.saveProduct = this.saveProduct.bind(this);
    }

    componentDidMount() {
        this.loadMerchants();
        this.loadAddressAuthorize();
        this.callListPrincipal();
    }

    loadMerchants(){
        let lstMerchants = sessionStorage.getItem('lstMerchants');

        this.setState({
            listMechants: JSON.parse(lstMerchants)
        });
    }

    loadAddressAuthorize(){
        this.usuarioService = new UsuarioService()

        this.usuarioService.getListAuthorizeExternalBtc().then(resp => {
            if(resp.status === 'success'){
                let listAddressAuthorize = resp.data
                console.log('status '+resp.status)
                this.setState({listAddressAuthorize});

            }else{
                //swal("ERROR", resp.messageResponse, "error");
            }
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });
    }

    callListPrincipal(){
        this.usuarioService = new UsuarioService()
        let params = {
            "date_ini":"",
            "date_end":"",
            "payment_method":"",
            "payment_type":"",
            "keyword":"",
            "status_payment":"",
            "account_merchant":""
        }

        this.usuarioService.postReportBtcPayment(params).then(resp => {
            if(resp.status === 'success'){
                let listPayments = resp.data
                console.log('status '+resp.status)
                this.setState({listPayments});
                this.showSucccess(resp.time_react)

            }else{
                //swal("ERROR", resp.messageResponse, "error");
            }
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });
    }

    /**
     * Metodo para mostrar mensaje de error cuando el usuario y/o password son incorrectos
     * params:
     * return: se habilita mensaje de error
     **/
    showSucccess(msgDetail) {
        let msg = { severity: 'success', summary: 'OK', detail: msgDetail};
        this.growl.show(msg);
    }

    showError(msgDetail) {
        let msg = { severity: 'error', summary: 'ERROR', detail: msgDetail};
        this.growl.show(msg);
    }

    /**
     * Metodo para mostrar mensaje de alerta cuando el usuario y/o password estan vacios
     * params:
     * return: se habilita mensaje de alerta
     **/
    showWarn() {
        let msg = { severity: 'warn', summary: 'AVISO', detail: 'You must specify username and password' };
        this.growl.show(msg);
    }

    /**
     * Metodo para mostrar mensaje de alerta generico
     * params:
     * return: se habilita mensaje de alerta
     **/
    showMsg(severityMsg, summaryMsg, detailMsg){
        let msg = { severity: severityMsg, summary: summaryMsg, detail: detailMsg };
        this.growl.show(msg);
    }


    hideDialog() {
        this.setState({
            submitted: false,
            productDialog: false,
            productDialogConfirm: false,
        });
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    openNew() {
        this.setState({
            submitted: false,
            productDialog: true
        });
    }


    paymentAddress = e =>  {
        const {
            address_id,
            amount

        } = this.state;
        let params={
            address_id: address_id,
            address: amount,
        }
        this.usuarioService = new UsuarioService();

        this.usuarioService.postAddExternalBtc(params).then(resp => {
            if(resp.status === 'success'){
                //let listPayments = resp.data
                console.log('status '+resp.status)
                console.log(resp.data)
                //this.showSucccess(resp.time_react)
                this.setState({
                    submitted: false,
                    productDialog: false,
                    product: this.emptyProduct
                });
                setTimeout(() => {
                    this.setState({
                        productDialogConfirm: true,
                    })
                }, 200);

            }else{
                //swal("ERROR", resp.messageResponse, "error");
            }
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });
    }

    saveProduct = e =>  {
        const {
            address_id,
            amount
        } = this.state;
        let msg = { severity: 'success', summary: 'OK', detail: ''};
        if (address_id > 0 && amount !== '' && amount > 0) {
            const params = {
                address_id: address_id,
                amount: amount
            }
            this.usuarioService = new UsuarioService();

            this.usuarioService.postPaymentExternalBtc(params).then(resp => {
                if(resp.status === 'success'){
                    this.setState({
                        productDialog: false,
                        address_id: 0,
                        amount: ''
                    });
                    msg.detail = resp.data.msg;
                    this.growl.show(msg);
                }else{
                    msg.summary = 'Error';
                    msg.detail = '';
                    msg.severity = 'error';
                    this.growl.show(msg);
                }
            }).catch(error => {
                //swal("ERROR", error.response.data.messageResponse, "error");
            });
        } else {
            msg.summary = 'Fields are required';
            msg.detail = 'Address and amount fields are required';
            msg.severity = 'error';
            this.growl.show(msg);
        }
    }


    render() {

        let header = (
            <div style={{'textAlign':'right'}}>
                <i className="pi pi-search" style={{margin:'4px 4px 0 0'}}></i>
                <InputText type="search" onInput={(e) => this.setState({globalFilter: e.target.value})} placeholder="Global Search" size="50"/>
            </div>
        );

        const productDialogFooter = (
            <React.Fragment>
                <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={this.saveProduct} />
            </React.Fragment>
        );

        const codeConfirmFooter = (
            <React.Fragment>
                <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={this.sendCodeConfirm} />
            </React.Fragment>
        );

        const {
            address_id,
            amount,
            listAddressAuthorize,
            code,
        } = this.state;


        return(

            <div className="pageWrapper">
                <Growl ref={(el) => this.growl = el} />
                <Card>
                    <CardTitle>Dashboard BTC</CardTitle>

                    <Toolbar className="p-mb-4 mb-10">
                        <Dropdown value={this.state.merchantId} options={this.state.listMechants} onChange={(e) => {this.setState({merchantId: e.value})}} placeholder="Select Merchant"/>
                        <Button className="ml-10" label='Search' onClick={this.callListPrincipal} />
                        <Button className="ml-10" label="New Payment" icon="pi pi-plus" onClick={this.openNew} />
                    </Toolbar>

                    <DataTable value={this.state.listPayments} selectionMode="multiple"
                               header={header} globalFilter={this.state.globalFilter} reorderableColumns={true}
                               paginator={true} rows={30}
                               resizableColumns={true} selection={this.state.dataTableSelectValue} onSelectionChange={(e) => this.setState({dataTableSelectValue: e.value})}>
                        <Column selectionMode="multiple" style={{width:'2em'}}/>
                        <Column field="address" header="address" sortable={true} style={{width:'20em'}}/>
                        <Column field="amount" header="amount" sortable={true}/>
                        <Column field="fee_amount" header="fee_amount" sortable={true}/>
                        <Column field="dcreated" header="Created" sortable={true} className="ta-right"/>
                        <Column field="time" header="time" sortable={true} className="ta-right"/>

                    </DataTable>


                    <Dialog visible={this.state.productDialog} style={{width: '450px', height: '400px'}}
                            header="Address" modal className="p-fluid" footer={productDialogFooter}
                            onHide={this.hideDialog}>
                        <div className="p-field mb-25">
                            <label htmlFor="address_id">Adress</label>
                            <Dropdown id='address_id' value={address_id} options={listAddressAuthorize}
                                      onChange={(e) => {
                                          this.setState({address_id: e.value})
                                      }} placeholder="Select Address" />
                        </div>
                        <div className="p-field mb-50">
                            <label htmlFor="amount">Amount</label>
                            <InputText id="amount" name="amount" type='number' value={amount} onChange={this.handleChange}/>
                        </div>
                    </Dialog>
                    <Dialog visible={this.state.productDialogConfirm} style={{width: '450px', height: '200px'}}
                            header="Confirm Code" modal className="p-fluid" footer={codeConfirmFooter}
                            onHide={this.hideDialog}>
                        <div className="p-field">
                            <label htmlFor="code">Code</label>
                            <InputText id="code" name="code" value={code} onChange={this.handleChange}/>
                            {this.state.submitted && !this.state.product.code && <small className="p-invalid">Code is required.</small>}
                        </div>
                    </Dialog>



                </Card>
            </div>
        );
    }
}

export default ReportPayment;
