import React, { Component } from 'react';
import Card from '../shared/Card';
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {Button} from "primereact/components/button/Button";
import CardTitle from "../shared/Card/Title";
import {HeisenbergService} from "../../service/heisenberg/HeisenbergService";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import { Growl } from 'primereact/growl';
import {InputText} from "primereact/components/inputtext/InputText";
import {Dialog} from "primereact/components/dialog/Dialog";
import {TabPanel, TabView} from "primereact/tabview";
import {Toolbar} from "primereact/toolbar";
import {ProgressSpinner} from "primereact/progressspinner";
import {formatCurrency} from "../utils/commonFunctions";
import {PaymentService} from "../../service/payment/PaymentService";
import {UsuarioService} from "../../service/login/UsuarioService";

class Donaciones extends Component {

    constructor(){
        super();
        this.apiHeisenberg = new HeisenbergService()
        this.state ={
            data : new FormData(),
            fileName : "",
            merchantId : "*",
            listMechants : [],
            listPayments: [],
            catBanks:[],
            bankId:"",
            globalFilter: null,
            dataTableSelectValue: {payment_desc:{}},
            chargedYet: false,
            refundDialog: false
        }

        this.callListPrincipal = this.callListPrincipal.bind(this);
        this.initCatalogos = this.initCatalogos.bind(this);
        this.actionBodyDetail = this.actionBodyDetail.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
    }

    componentDidMount() {
        this.initCatalogos();
        this.callListPrincipal();
    }

    initCatalogos(){
        this.apiHeisenberg = new HeisenbergService()

        let lstMerchants = sessionStorage.getItem('lstMerchants');
        this.setState({
            listMechants: JSON.parse(lstMerchants)
        });
    }

    callListPrincipal(){
        this.usuarioService = new UsuarioService()
        let params = {merchant_key:this.state.merchantId}

        this.usuarioService.postReportDonationsTrans(params).then(resp => {
            if(resp.status === 'success'){
                const listPayments = resp.data
                listPayments.forEach(element => {
                    element.amount_total = formatCurrency(element.amount_total);
                    element.fee_total = formatCurrency(element.fee_total);
                    if(element.merchant_user_desc == null){
                        element.userDesc = 'Guest';
                    } else {
                        element.userDesc = element.merchant_user_desc.username;
                    }
                });

                this.setState({
                    listPayments,
                    chargedYet: true});
                this.showSucccess(resp.time_react)

            }else{
                //swal("ERROR", resp.messageResponse, "error");
            }
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });
    }

    actionBodyDetail() {
        this.setState({
            code:"",
            operation:"payment",
            displayDialog: true
        });
    }

    refundAction = () => {
        const {
            dataTableSelectValue
        } = this.state;

        if (dataTableSelectValue.id !== undefined) {
            this.paymentService = new PaymentService();
            const params = {
                merchantKey: dataTableSelectValue.merchant,
                donationId: dataTableSelectValue.id
            };

            this.paymentService.postDonationRefund(params).then(resp => {
                if(resp.status === 'success'){
                    if(resp.data['transaction-status']==="FAIL"){
                        this.showError(resp.data['transaction-message']);
                    } else{
                        this.showSucccess("Refund success.");
                    }
                    this.hideDialog();
                    this.callListPrincipal();
                } else {
                }
            }).catch(error => {
            });
        } else {
            const msg = { severity: 'error', summary: 'You need', detail: 'Select a row to refund'};
            this.growl.show(msg);
        }
    }

    /**
     * Metodo para mostrar mensaje de error cuando el usuario y/o password son incorrectos
     * params:
     * return: se habilita mensaje de error
     **/
    showSucccess(msgDetail) {
        let msg = { severity: 'success', summary: 'OK', detail: msgDetail};
        this.growl.show(msg);
    }

    showError(msgDetail) {
        let msg = { severity: 'error', summary: 'ERROR', detail: msgDetail};
        this.growl.show(msg);
    }

    /**
     * Metodo para mostrar mensaje de alerta cuando el usuario y/o password estan vacios
     * params:
     * return: se habilita mensaje de alerta
     **/
    showWarn() {
        let msg = { severity: 'warn', summary: 'AVISO', detail: 'You must specify username and password' };
        this.growl.show(msg);
    }

    /**
     * Metodo para mostrar mensaje de alerta generico
     * params:
     * return: se habilita mensaje de alerta
     **/
    showMsg(severityMsg, summaryMsg, detailMsg){
        let msg = { severity: severityMsg, summary: summaryMsg, detail: detailMsg };
        this.growl.show(msg);
    }

    hideDialog() {
        this.setState({
            refundDialog: false,
        });
    }
    showRefundDialog = () => {
        const {
            dataTableSelectValue
        } = this.state;
        if (dataTableSelectValue.id === undefined) {
            const msg = { severity: 'error', summary: 'You need', detail: 'Select a row to refund'};
            this.growl.show(msg);
        } else {
            this.setState({refundDialog: true});
        }
    }


    render() {
        const header = (
            <div style={{'textAlign':'right'}}>
                <i className="pi pi-search" style={{margin:'4px 4px 0 0'}}></i>
                <InputText type="search" onInput={(e) => this.setState({globalFilter: e.target.value})} placeholder="Global Search" size="50"/>
            </div>
        );

        const dialogFooterCancel = <div className="ui-dialog-buttonpane p-clearfix">
            <Button label="Submit" icon="pi pi-check" onClick={this.callAction}/>
        </div>;

        const refundFooter = (
            <React.Fragment>
                <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Refund/Disputes" icon="pi pi-check" className="p-button-danger" onClick={this.refundAction} />
            </React.Fragment>
        );

        return(
            <div className="pageWrapper">
                <Growl ref={(el) => this.growl = el} />
                <Card>
                    <CardTitle>Donations</CardTitle>
                    <div className="p-grid">
                        <div className="col-9 ta-right" style={{position: "relative", marginTop: -20}}>
                            <ProgressSpinner style={{width: '50px', height: '60px'}}
                                             animationDuration=".7s"/>
                    </div>
                    </div>

                    <Toolbar className="p-mb-4 mb-10">
                        <Dropdown value={this.state.merchantId} options={this.state.listMechants} onChange={(e) => {
                            this.setState({merchantId: e.value})
                        }} placeholder="Select Merchant"
                            className="mr-5"/>
                        <Button label="Search" onClick={this.callListPrincipal} />
                        <Button label="Detail" onClick={this.actionBodyDetail}/>
                        <Button label="Refund/Disputes" className="p-button-danger ta-right" onClick={this.showRefundDialog} />
                    </Toolbar>

                    <DataTable value={this.state.listPayments}
                               header={header} globalFilter={this.state.globalFilter} reorderableColumns={true}
                               selectionMode="single" paginator={true} rows={30}
                               resizableColumns={true}
                               selection={this.state.dataTableSelectValue} onSelectionChange={(e) => this.setState({dataTableSelectValue: e.value})}>
                        <Column selectionMode="single" style={{width:'3em'}}/>
                        <Column field="type_donation" header="Type" sortable={true} className="ta-right"/>
                        <Column field="userDesc" header="User" sortable={true}/>
                        <Column field="amount_total" header="Amount" sortable={true} className="ta-right"/>
                        <Column field="fee_total" header="Fee" sortable={true} className="ta-right"/>
                        <Column field="gift" header="Gift" sortable={true} className="ta-right"/>
                        <Column field="dcreated" header="Created" sortable={true} className="ta-right"/>
                        <Column field="time" header="Time" sortable={true} className="ta-right"/>

                    </DataTable>

                    <Dialog visible={this.state.displayDialog} style={{width: '1000px'}} header="Transaction Information" modal={true} footer={dialogFooterCancel} onHide={() => this.setState({displayDialog: false})}
                            blockScroll={false}>
                        <TabView>
                            <TabPanel header="Lines">
                                <DataTable value={this.state.dataTableSelectValue.lines}
                                           header={header} reorderableColumns={true}
                                           selectionMode="single" paginator={true} rows={30}
                                           resizableColumns={true}
                                           selection={this.state.dataTableSelectValue}
                                           onSelectionChange={(e) => this.setState({dataTableSelectValue: e.value})}>
                                    <Column selectionMode="single" style={{width:'3em'}}/>
                                    <Column field="id" header="id" sortable={true}/>
                                    <Column field="product_desc.label" header="type" sortable={true}/>
                                    <Column field="amount" header="amount" sortable={true}/>
                                    <Column field="fee" header="fee" sortable={true}/>
                                    <Column field="dcreated" header="Created" sortable={true}/>

                                </DataTable>
                            </TabPanel>
                            <TabPanel header="Payment">
                                <div className="p-grid p-fluid">
                                    <div className="p-col-6" style={{padding:'.75em'}}><label htmlFor="label">uuid</label></div>
                                    <div className="p-col-6" style={{padding:'.75em'}}><label htmlFor="label">{this.state.dataTableSelectValue.uuid}</label></div>
                                    <div className="p-col-6" style={{padding:'.75em'}}><label htmlFor="label">Amount</label></div>
                                    <div className="p-col-6" style={{padding:'.75em'}}><label htmlFor="label">{this.state.dataTableSelectValue.payment_desc.amount}</label></div>
                                    <div className="p-col-6" style={{padding:'.75em'}}><label htmlFor="label">Fees</label></div>
                                    <div className="p-col-6" style={{padding:'.75em'}}><label htmlFor="label">{this.state.dataTableSelectValue.payment_desc.fees}</label></div>
                                    <div className="p-col-6" style={{padding:'.75em'}}><label htmlFor="label">Result txt</label></div>
                                    <div className="p-col-6" style={{padding:'.75em'}}><label htmlFor="label">{this.state.dataTableSelectValue.payment_desc.result_api}</label></div>
                                </div>
                            </TabPanel>
                        </TabView>
                    </Dialog>
                    <Dialog visible={this.state.refundDialog} style={{width: '450px', height: '200px'}}
                            header="Confirm refund" modal className="p-fluid" footer={refundFooter}
                            onHide={this.hideDialog}>
                        <div className="p-field">
                            <p><b>User: </b> {this.state.dataTableSelectValue.userDesc}</p>
                            <p><b>Amount: </b> {this.state.dataTableSelectValue.amount_total}</p>
                            <p><b>Fee Total: </b> {this.state.dataTableSelectValue.fee_total}</p>
                            <p><b>Gift: </b> {this.state.dataTableSelectValue.gift}</p>
                            <p><b>Created: </b> {this.state.dataTableSelectValue.dcreated} {this.state.dataTableSelectValue.time}</p>
                        </div>
                    </Dialog>
                </Card>
            </div>
        );
    }
}

export default Donaciones;
