import React, {Component} from "react";
import Card from "../shared/Card";
import CardTitle from "../shared/Card/Title";
import {Calendar} from "primereact/calendar";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/components/button/Button";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {HeisenbergService} from "../../service/heisenberg/HeisenbergService";
//import {UsuarioService} from "../../service/login/UsuarioService";
import {Dropdown} from "primereact/components/dropdown/Dropdown";

class ReportLevel1 extends Component {
    constructor() {
        super();
        this.state = {
            date1: null,
            date2: null,
            columns:[],
            dynamicColumns:[],
            listFeeProvider: [],
            dataTableSelectValue : new FormData(),
            merchants:[],
            lstMerchants:[],
        };
        this.callReport = this.callReport.bind(this);
    }

    componentDidMount() {

        let lstMerchants = sessionStorage.getItem('lstMerchants');

        this.setState({
            lstMerchants: JSON.parse(lstMerchants)
        });
    }

    callReport() {
        const apiHeisenberg = new HeisenbergService();
        apiHeisenberg.postFeeReport0({
            "merchant_key": this.state.merchants,
            "nivel": 1,
            "fee_provider_id": "1"
        }).then(resp => {
            if (resp.data.lstReport.length > 0) {
                let data = resp.data.lstReport;
                let columns = resp.data.lstColumns

                this.setState({listFeeProvider: data});
                this.setState({columns: columns});
                let dynamicColumns = this.state.columns.map((col,i) => {
                    return <Column key={col.field} field={col.field} header={col.header}
                                   sortable={true} style={{width:'5em'}}/>;
                });
                this.setState({dynamicColumns: dynamicColumns});
                this.forceUpdate();
            } else {
                //swal("ERROR", resp.messageResponse, "error");
            }
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });
    }

    callExportExcel= e => {

        console.log(this.state.dataTableSelectValue)
        const apiHeisenberg = new HeisenbergService();
        apiHeisenberg.getFeeProvidersNivel(this.state.lstMerchants,1,1).then(resp => {
            console.log("responseeee");
            console.log(resp);
            //var file =new File([resp], "data.xls", { type: 'application/ms-excel' })
            //var file = new File(["Hello, world!"], "hello world.txt", {type: "text/plain;charset=utf-8"});
            //var blob =  new Blob(resp, {type: "application/ms-excel"});
            //FileSaver.saveAs(blob);
            const downloadUrl = window.URL.createObjectURL(resp);

            const link = document.createElement('a');

            link.href = downloadUrl;

            link.setAttribute('download', 'ReportFeeNivel1.xls'); //any other extension

            document.body.appendChild(link);

            link.click();

            link.remove();

        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });


    }

    render() {

        const {
            merchant_key,
            lstMerchants,
            //listPayments,
            //dataTableSelectValue,
        } = this.state;
        return (
            <div className="pageWrapper">
                <Card>
                    <CardTitle>Report Processor</CardTitle>

                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col">
                            <label htmlFor="dateFrom">Date From</label>
                            <Calendar value={this.state.date1} onChange={(e) => this.setState({date1: e.value})}
                                      showIcon={true}/>
                        </div>
                        <div className="p-field p-col">
                            <label htmlFor="dateTo">Date To</label>
                            <Calendar value={this.state.date2} onChange={(e) => this.setState({date2: e.value})}
                                      showIcon={true}/>
                        </div>
                        <div className="p-field p-col">
                            <label htmlFor="paymentMethod">Payment Method</label>
                            <InputText id="paymentMethod" type="text"/>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col">
                            <label htmlFor="status">Status</label>
                            <InputText id="status" type="text"/>
                        </div>
                        <div className="p-field p-col">
                            <label htmlFor="type">Type</label>
                            <InputText id="type" type="text"/>
                        </div>
                        <div className="p-field p-col">
                            <label htmlFor="account">Merchants</label>
                            <Dropdown value={merchant_key} options={lstMerchants} style={{width: '100%'}}
                                      onChange={(e) => {
                                          this.setState({merchant_key: e.value })
                                      }}
                                      placeholder="Select a Merchant"/>
                        </div>
                    </div>



                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col">
                            <label htmlFor="keyword">Keyword</label>
                            <InputText id="keyword" type="text"/>
                        </div>
                        <div className="p-field p-col"></div>
                        <div className="p-field p-col" style={{paddingTop: '24px'}}>
                            <Button label='Generate Report' onClick={this.callReport}/>
                        </div>

                        <div className="p-field p-col" style={{paddingTop:'24px'}}>
                            <Button label='Export Excel' onClick={this.callExportExcel}/>
                        </div>

                    </div>

                    <DataTable value={this.state.listFeeProvider} selectionMode="single" header="Rows"
                               selection={this.state.dataTableSelectValue} reorderableColumns={true}
                               paginator={true} rows={30} resizableColumns={true}
                               onSelectionChange={(e) => this.setState({dataTableSelectValue: e.value})}>
                        {this.state.dynamicColumns}
                    </DataTable>
                </Card>
            </div>
        );
    }
}

export default ReportLevel1;