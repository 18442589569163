import React, {Component} from "react";
import {HeisenbergService} from "../../service/heisenberg/HeisenbergService";
import swal from "sweetalert";
import CardTitle from "../shared/Card/Title";
import Card from "../shared/Card";
import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {Button} from "primereact/components/button/Button";
//import {UsuarioService} from "../../service/login/UsuarioService";

class ListInvoicePayments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            merchant_key:'*',
            listPayments:[],
            dataTableSelectValue:{},
            merchants:[],
        }

    }

    componentDidMount() {

        let lstMerchants = sessionStorage.getItem('lstMerchants');

        this.setState({
            merchants: JSON.parse(lstMerchants)
        });
    }

    callListInvoicePayment = () =>{
        const {
            merchant_key,
        } = this.state;
        const apiHeisenberg = new HeisenbergService()
        apiHeisenberg.getListInvoices({
            "merchant_key" : merchant_key
        }).then(resp => {
            if(resp.status === 'success'){
                this.setState({listPayments: resp.data});
            }else{
                swal({title: "ERROR", text: 'Fail get list payments', icon: "error"});
            }
        }).catch(error => {
            swal({title: "ERROR", text: error.response.data.messageResponse, icon: "error"});
        });
    }

    render() {
        const {
            merchant_key,
            merchants,
            listPayments,
            dataTableSelectValue,
        } = this.state;
        return (
            <Card>
                <CardTitle>List of payments</CardTitle>
                <div className="p-grid">
                    <div className="p-col-3">
                        <label>Select Merchant:* </label>
                    </div>
                    <div className="p-col-4">
                        <Dropdown value={merchant_key} options={merchants} style={{width: '100%'}}
                                  onChange={(e) => {
                                      this.setState({merchant_key: e.value })
                                  }}
                                  placeholder="Select a Merchant"/>
                    </div>
                    <div className="p-col-4">
                        <Button label="Submit Merchant" onClick={this.callListInvoicePayment}/>
                    </div>
                </div>
                {listPayments.length > 0 &&
                <DataTable value={listPayments} selectionMode="single" resizableColumns={true}
                           selection={dataTableSelectValue}
                           onSelectionChange={(e) => this.setState({dataTableSelectValue: e.value})}>
                    <Column field="merchant" header="Merchant" sortable={true}/>
                    <Column field="type" header="Type" sortable={true}/>
                    <Column field="email" header="Email" sortable={true}/>
                    <Column field="first_name" header="First Name" sortable={true}/>
                    <Column field="last_name" header="Last Name" sortable={true}/>
                    <Column field="invoice_number" header="Invoice Number" sortable={true}/>
                    <Column field="amount" header="Amount" sortable={true}/>
                    <Column field="comments" header="comments" sortable={true}/>
                </DataTable>
                }
            </Card>
        );
    }

}

export default ListInvoicePayments;