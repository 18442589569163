import React, { Component } from 'react';
import Card from '../shared/Card';
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import CardTitle from "../shared/Card/Title";
import {HeisenbergService} from "../../service/heisenberg/HeisenbergService";
import {Chart} from "primereact/components/chart/Chart";
import {formatCurrency} from "../utils/commonFunctions";
import {UsuarioService} from "../../service/login/UsuarioService";
import TextBold from "../shared/Typography/TextBold";

class Dashboardbtc extends Component {
    constructor(){
        super();
        this.apiHeisenberg = new HeisenbergService()
        this.state = {
            data: new FormData(),
            fileName: "",
            merchantId: "",
            listMechants: [],
            listAddress: [],
            rates: [],
            catBanks: [],
            bankId: "",
            balanceNode: 0.0,
            balanceRecived: 0.0,
            balanceRecivedUnConfirmed: 0.0,
            balancePayment: 0.0,
            serieGeneral: {},
            headers_dashboard: {
                total_invoices_BTC: 0,
                confirming_BTC: 0,
                received_BTC: 0,
                transferred_BTC: 0,
                total_invoices: 0
            },
            rate_btc_usd: 0,
            componet_box_active: 'total_invoices_BTC',
            totalBox1: {
                invoicesReceived: 0,
                openInvoicesBTC: 0,
                openInvoices: 0,
                issuedInvoices: 0,
                fees: '0'
            },
            monthlyDataset:{},
            donation_period:'',
        }

        this.callListPrincipal = this.callListPrincipal.bind(this);
        this.initCatalogos = this.initCatalogos.bind(this);
        this.initSeries = this.initSeries.bind(this);
        this.getBalanceNode = this.getBalanceNode.bind(this);
        this.getRateBtcUsd = this.getRateBtcUsd.bind(this);
        this.getHeadersBalanceBTC = this.getHeadersBalanceBTC.bind(this);
    }

    componentDidMount() {
        this.apiHeisenberg = new HeisenbergService();
        this.initCatalogos();
        this.initSeries();
        this.getBalanceNode();
        this.getHeadersBalanceBTC();
        //this.getRateBtcUsd();
    }

    getBalanceNode(){

        this.apiHeisenberg = new HeisenbergService()

        this.apiHeisenberg.getReportBtcSumaryAccount().then(resp => {
            if(resp.status === 'success'){
                console.log("getBalanceNode");
                const dictBalanceAccount=resp.balance_account;
                const dictBalanceAccountUnconfirmed=resp.balance_account_unconfirmed;

                console.log(JSON.stringify(dictBalanceAccount));

                this.setState({balanceNode: dictBalanceAccount['1']['total_balance']});
                this.setState({balanceRecived: dictBalanceAccount['1']['total_balance']});
                this.setState({balanceRecivedUnConfirmed: dictBalanceAccountUnconfirmed['1']['total_balance']});
                this.setState({balancePayment: dictBalanceAccount['2']['total_balance']});




                //this.setState({balanceNode: resp.balance_account_unconfirmed});

            }else{
                //swal("ERROR", resp.messageResponse, "error");
            }
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });

    }

    getRateBtcUsd(){

        this.apiHeisenberg = new HeisenbergService()

        this.apiHeisenberg.getRateBtcUsd().then(resp => {
            if(resp.status === 'success'){
                this.setState({
                    rates: resp.data
                });

            }else{
                //swal("ERROR", resp.messageResponse, "error");
            }
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });

    }

    initCatalogos(){
        this.apiHeisenberg = new HeisenbergService()

        this.apiHeisenberg.getAllMerchants().then(resp => {
            if(resp.status === 'success'){
                //let listApisx = resp.data
                let listMechants=[]
                console.log('status '+resp.status)
                //listApisx.map(function (elem) {
                //    listMechants.push({label:elem.label,value:elem.key})
                //})
                this.setState({listMechants});

            }else{
                //swal("ERROR", resp.messageResponse, "error");
            }
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });


        this.usuarioService = new UsuarioService()

        this.usuarioService.getReportBtcLastTransactions().then(resp => {
            if(resp.status === 'success'){
                let listRecents = resp.data
                this.setState({listRecents});

            }else{
                //swal("ERROR", resp.messageResponse, "error");
            }
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });


    }

    getHeadersBalanceBTC(){
        this.apiHeisenberg.getHeadersBalanceBTC().then(resp => {
            if(resp.status === 'success'){
                const headers_dashboard = {
                    total_invoices_BTC: resp.data.totalInvoices.total_balance,
                    confirming_BTC: resp.data.totalConfirmations.total_balance,
                    received_BTC: resp.data.totalRecived.total_balance,
                    transferred_BTC: resp.data.totalTranfer.total_balance,
                    total_invoices: 0
                };
                const totalBox = {
                    invoicesReceived: resp.data.totalInvoicesPocentaje.total_invoices_porcentaje,
                    openInvoicesBTC: resp.data.totalInvoicesPocentaje.total_sum_open_invoices_btc,
                    openInvoices: resp.data.totalInvoicesPocentaje.total_sum_open_invoices_usd,
                    issuedInvoices: resp.data.totalInvoicesPocentaje.total_isued_invoices,
                    fees: resp.data.totalFees.total_balance,
                }
                this.setState({
                    'headers_dashboard': headers_dashboard,
                    'totalBox1': totalBox,
                    'rate_btc_usd': resp.data.rate_btc_usd });
            }
        }).catch(error => {
        });
    }

    initSeries(){

        this.apiHeisenberg.postReportBtcHistorySerie({"currency":"USD",
            "type":"1"}).then(resp => {
            if(resp.status === 'success'){
                let serieGeneral = resp.data
                this.setState({serieGeneral});

            }else{
                //swal("ERROR", resp.messageResponse, "error");
            }
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });

        this.usuarioService = new UsuarioService()

        this.usuarioService.getReportBtcMonthly().then(resp => {
            if(resp.status === 'success'){
                let monthlyDataset = resp.data
                this.setState({monthlyDataset});

            }else{
                //swal("ERROR", resp.messageResponse, "error");
            }
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });



    }

    callListPrincipal(){
        this.apiHeisenberg = new HeisenbergService()
        let params = {merchantKey:this.state.merchantId,type:'1'}

        this.apiHeisenberg.postGetAllAddressByMerchant(params).then(resp => {
            if(resp.status === 'success'){
                let listAddress = resp.arrayAddress
                console.log('status '+resp.status)
                this.setState({listAddress});

            }else{
                //swal("ERROR", resp.messageResponse, "error");
            }
        }).catch(error => {
            //swal("ERROR", error.response.data.messageResponse, "error");
        });
    }

    showBox = data => () => {
        //this.setState({componet_box_active: data}); => mostar component box
        this.setState({componet_box_active: 'total_invoices_BTC'});
    }

    render() {

       // const mapData = {
         //CN: 100000,
           //IN: 9900,
            //SA: 86,
            //EG: 70,
            //SE: 0,
            //FI: 0,
            //FR: 0,
            //US: 20,
            //MX: 10,
            //};


        const headersDashboard = (
            <div>
                <div className="p-grid dashboard">
                    <div className="p-col-12 p-md-6 p-lg-4 p-offset-4">
                        <div className="p-grid overview-box overview-box-1 overview-box-slim">
                            <div className="overview-box-title">
                                <i className="fa fa-inbox"></i>
                                <span>BTC/USD: {formatCurrency(this.state.rate_btc_usd)}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-grid dashboard">
                    <div className="p-col-12 p-md-6 p-lg-3">
                        <div className="p-grid overview-box overview-box-1" onClick={this.showBox('total_invoices_BTC')}>
                            <div className="overview-box-title">
                                <i className="fa fa-inbox"></i>
                                <span>Total invoices BTC</span>
                            </div>
                            <div className="overview-box-count ta-right">&#8383; {this.state.headers_dashboard.total_invoices_BTC}</div>
                            <div className="overview-box-stats" style={{marginTop: -7, fontSize: 14}}>
                                Total Invoices {this.state.headers_dashboard.total_invoices}
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12 p-md-6 p-lg-3">
                        <div className="p-grid overview-box overview-box-2" onClick={this.showBox('confirming_BTC')}>
                            <div className="overview-box-title">
                                <i className="fa fa-map-pin"></i>
                                <span>Unconfirmed BTC</span>
                            </div>
                            <div className="overview-box-count ta-right">&#8383; {this.state.headers_dashboard.confirming_BTC}</div>
                        </div>
                    </div>
                    <div className="p-col-12 p-md-6 p-lg-3">
                        <div className="p-grid overview-box overview-box-3" onClick={this.showBox('received_BTC')}>
                            <div className="overview-box-title">
                                <i className="fa fa-folder"></i>
                                <span>Received BTC</span>
                            </div>
                            <div className="overview-box-count ta-right">&#8383; {this.state.headers_dashboard.received_BTC}</div>
                        </div>
                    </div>
                    <div className="p-col-12 p-md-6 p-lg-3">
                        <div className="p-grid overview-box overview-box-4" onClick={this.showBox('transferred_BTC')}>
                            <div className="overview-box-title">
                                <i className="fa fa-user"></i>
                                <span>Paid Out BTC</span>
                            </div>
                            <div className="overview-box-count ta-right">&#8383; {this.state.headers_dashboard.transferred_BTC}</div>
                        </div>
                    </div>
                </div>
            </div>
        );

        return(
            <div className="pageWrapper" >
                {headersDashboard}
                {/*<Component_box boxName={this.state.componet_box_active} serieGeneral={this.state.monthlyDataset} totalBox1={this.state.totalBox1}/>*/}
                <div className="p-grid">
                    <div className="p-col-6">
                        <Card height="height-100">
                            <div className="p-grid p-justify-between p-align-center">
                                <CardTitle className="small">BTC</CardTitle>
                            </div>
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="mb-15 ta-center">
                                        <TextBold className="font-16">Rates</TextBold>
                                    </div>
                                    <div>
                                        <Chart type="line" data={this.state.serieGeneral} height="100" />
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>


                    <div className="p-col-6">
                        <Card height="height-100">
                            <DataTable value={this.state.listRecents} rows={10}>
                                <Column field="invoice_number" header="Invoice #"/>
                                <Column field="amount" header="Amount" />
                                <Column field="received" header="Received" />
                                <Column field="unconfirmed" header="Unconfirmed" />
                                <Column field="created" header="Created" />
                                <Column field="time" header="Time" />
                            </DataTable>

                        </Card>
                    </div>
                </div>

            </div>
        );
    }

}

export default Dashboardbtc;
