import React, { Component } from 'react';
//import DashboardBest from "./dashboardAirtm";
import Transactions from "./transactions";
//import SubMenu from "../shared/SubMenu";
//import SubMenuItem from "../shared/SubMenu/Item";
//import {formatCurrency} from "../utils/commonFunctions";
//import ProfileTransactions from "./profile";
//import TokenTransactions from "./token";
//import DashboardAirtm from "./dashboardAirtm";
//import UnloadsCard from "../UnloadsCard";

class Cash extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //opcionMenuSelected:'Dashboard',
            opcionMenuSelected:'Transactions',
            transaction: null,
        }

        this.showBox = this.showBox.bind(this);
        this.transactionSelected = this.transactionSelected.bind(this);
    }

    showBox = data => () => {
        this.setState({opcionMenuSelected: data});
    }

    transactionSelected(transaction) {
        this.setState({
            transaction,
            opcionMenuSelected: "TokenBalance",
        });
    }

    render() {
        return (
            <div className="dashboard-banking">

                <div className="p-grid overview-boxes">
                    {/*<div className="p-col-12 p-md-4 p-lg-4" onClick={this.showBox('Dashboard')} style={{cursor:'pointer'}}>
                        <div className="overview clearfix">
                            <div className="overview-icon">
                                <img src="assets/layout/images/dashboard-banking/icon-transfer.png" alt="avalon-layout"
                                     className="icon-transfer"/>
                            </div>

                            <div className="overview-text">
                                <span className="overview-title"></span>
                                <span className="overview-subtitle">Dashboard</span>

                            </div>
                        </div>
                    </div>*/}

                    <div className="p-col-12 p-md-4 p-lg-4" onClick={this.showBox('Transactions')} style={{cursor:'pointer'}}>
                        <div className="overview clearfix">
                            <div className="overview-icon">
                                <img src="assets/layout/images/dashboard-banking/icon-transactions.png"
                                     alt="avalon-layout"
                                     className="icon-creditcards"/>
                            </div>

                            <div className="overview-text">
                                <span className="overview-title"></span>
                                <span className="overview-subtitle">Cash Transactions</span>
                            </div>
                        </div>
                    </div>
                    {/*<div className="p-col-12 p-md-4 p-lg-4" onClick={this.showBox('TokenBalance')} style={{cursor:'pointer'}}>
                        <div className="overview clearfix">
                            <div className="overview-icon">
                                <img src="assets/layout/images/dashboard-banking/icon-creditcards.png"
                                     alt="avalon-layout"
                                     className="icon-creditcards"/>
                            </div>

                            <div className="overview-text">
                                <span className="overview-title"></span>
                                <span className="overview-subtitle">Card Balance</span>
                            </div>*
                        </div>
                    </div>*/}

                    {/*<div className="p-col-12 p-md-3 p-lg-3" onClick={this.showBox('UnloadsCard')} style={{cursor:'pointer'}}>
                        <div className="overview clearfix">
                            <div className="overview-icon">
                                <img src="assets/layout/images/dashboard-banking/icon-creditcards.png"
                                     alt="avalon-layout"
                                     className="icon-creditcards"/>
                            </div>

                            <div className="overview-text">
                                <span className="overview-title"></span>
                                <span className="overview-subtitle">Unloads Card</span>
                            </div>
                        </div>
                    </div>*/}

                </div>

                {/* Page Content */}
                {/*(this.state.opcionMenuSelected==='Dashboard') && <DashboardAirtm />*/}
                {(this.state.opcionMenuSelected==='Transactions') && <Transactions transactionSelected={this.transactionSelected}/>}
                {/*(this.state.opcionMenuSelected==='Profile') && <ProfileTransactions />}
                {(this.state.opcionMenuSelected==='TokenBalance') && <TokenTransactions transaction={this.state.transaction}/>}
                {(this.state.opcionMenuSelected==='UnloadsCard') && <UnloadsCard />*/}
            </div>
        );
    }
}

export default Cash;
