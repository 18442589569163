import React, { Component } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';

import highMaps from 'highcharts/highmaps';
import highchartsExporting from 'highcharts/modules/exporting';
import highchartsData from 'highcharts/modules/data';
import highchartsAccessibility from 'highcharts/modules/accessibility';
import HighchartsReact from 'highcharts-react-official';

class WorldMap extends Component {

    constructor() {
        super();
        this.state = {
            loading: true,
            chargedYet: '',
            activeIndex: 0,
            cartOP: {},
        }

        this.initMap = this.initMap.bind(this);
    }

    afterChartCreated = (chart) => {
        // Highcharts creates a separate chart instance during export
        if (!chart.options.chart.forExport) {
            this.internalChart = chart;
        }
    }

    componentDidMount() {
        const self = this;
        if (typeof highMaps === 'object') {
            self.initMap();
            // init the module
            highchartsExporting(highMaps);
            highchartsData(highMaps);
            highchartsAccessibility(highMaps);

        }

        this.setState({ loading: false, chargedYet: 'loaded' });
    }

    initMap = async () => {
        debugger
        let dataMaps = this.props.mapData;
        console.log("datamaps");
        console.log(dataMaps);
        debugger
        const topology = await fetch('https://code.highcharts.com/mapdata/custom/world.topo.json').then((response) => response.json());
        const data = await fetch('https://cdn.jsdelivr.net/gh/highcharts/highcharts@v7.0.0/samples/data/world-population-density.json').then((response) => response.json());
        console.log("data")
        console.log(data)
        let maxValue = Math.max.apply(Math, dataMaps.map((obj) => obj.value));
        debugger
        console.log(maxValue)
        const projection = highMaps.merge({
            name: undefined
        }, {
            equalearth: {
                name: 'EqualEarth',
                projectedBounds: 'world'
            },
            lcc: {
                name: 'LambertConformalConic',
                parallels: [30, 40],
                projectedBounds: { x1: -200, y1: -200, x2: 200, y2: 200 }
            },
            miller: {
                name: 'Miller',
                projectedBounds: 'world'
            },
            ortho: {
                name: 'Orthographic',
                projectedBounds: 'world'
            },
            webmerc: {
                name: 'WebMercator',
                projectedBounds: 'world'
            }
        }["miller"] || {});

        const obj = {
            tooltip: {
                pointFormat: '{point.name}: <b>{point.value}</b>',
                formatter: function() {
                    const value = this.point.value === 0.10 ? '0.00' : this.point.value.toFixed(2);
                    return this.point.name + ': <b>$' + value + '</b>';
                }
            },
            chart: {
                height: '65%',
                spacing: [10, 1, 10, 1]
            },
            title: {
                text: null
            },
            colorAxis: {
                min: 1,
                max: maxValue,
                type: 'logarithmic',
                minColor: '#c3edff',
                maxColor: '#005175',
                labels: {
                    formatter: function() {
                        return '$' + this.value;
                    }
                }
            },
            mapView: {
                //projection

                projection: {
                    name: 'WebMercator'
                }
            },
            series: [
                {
                    data: dataMaps,
                    mapData: topology,
                    joinBy: ['iso-a2', 'code'],
                    name: 'Population density',
                }
            ]
        }
        console.log(projection);
        debugger
        this.setState({ cartOP: obj });
        debugger

        console.log(obj);
    }

    render() {
        return (
            <div className="grid">
                <div className="col-8">
                    <div className="card" style={{height: '300px', width: '100%'}}>
                        {!this.state.chargedYet ? (
                            <div className="col-9 ta-right" style={{ position: 'absolute', marginTop: -60 }}>
                                <ProgressSpinner style={{ width: '50px', height: '50px' }} animationDuration=".5s" />
                            </div>
                        ) : (
                            <HighchartsReact highcharts={highMaps} options={this.state.cartOP} constructorType={'mapChart'} callback={this.afterChartCreated} />
                        )}
                    </div>
                </div>
            </div>
        );
    }

}

export default WorldMap;
